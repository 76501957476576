<template>
  <div>
    <template v-if="descriptionsCalculated">
      <v-skeleton-loader
        type="list-item-three-line,list-item-three-line,list-item-three-line,list-item-three-line"
      ></v-skeleton-loader>
    </template>

    <div v-else class = "container">
      <div class="mb-8" v-for="level in descriptionArray" >
        {{ getCurrentLevel(level) }}
        <v-divider v-if="levelIsNotEmpty(level)" class = "mb-2">
        </v-divider>
        <div class="mb-4" v-for="criteria in orderVarsByCriteria(level)">
          <i> Criterio {{criteria.criteria}} </i>
          <v-divider></v-divider>
          <div class="mb-1" v-for="variable in criteria.variables">
            <b class="var-name">{{variable.node.name}}</b>&nbsp;
            - <b>Tipo:</b> {{variable.node.type}} {{variable.node.property}}
            - <b>Descripcion:</b> {{variable.node.description}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';

export default {
  name: 'instructionsVarDescription',
  props: {
    criteriaData: Array,
    surveyTree: String,
    projectObjective: String,
  },
  components: {
  },
  data: () => ({
    descriptionsCalculated: false,
    descriptionArray: [],
  }),
  mounted() {
    this.$set(this, 'descriptionArray', this.testTreeLevelTrasversal(JSON.parse(this.surveyTree)));
    console.log(this.descriptionArray);
  },
  methods: {
    orderVarsByCriteria(level) {
      const orderedArray = [];
      if (level) {
        const unique = [...new Set(level.map(item => item.criteria))];
        const group = _.groupBy(level, l => l.criteria);
        for (let i = 0; i < unique.length; i += 1) {
          orderedArray.push({ criteria: unique[i], variables: group[unique[i]] });
        }
      }
      return orderedArray;
    },
    levelIsNotEmpty(level) {
      if (level) return true;
      return false;
    },
    getCurrentLevel(level) {
      if (level) return `Nivel ${level[0].level - 1}`;
      return '';
    },
    testTreeLevelTrasversal(root) {
      if (root == null) {
        return [];
      }
      const queue = [];
      queue.push({ level: 1, node: root });
      console.log(queue);
      const result = [];
      while (queue.length > 0) {
        const curr = queue.shift();
        const { level } = curr;
        const parentCriteria = curr.node.name ?? this.projectObjective;
        if (result[level] == null) {
          result[level] = [];
        }
        result[level].push(curr);
        for (let i = 0; i < curr.node.children.length; i += 1) {
          queue.push({ level: level + 1, node: curr.node.children[i], criteria: parentCriteria });
        }
      }
      for (let i = 0; i < 2; i += 1) result.shift();
      return result;
    },
  },
};
</script>

<style>

  .label{
    background-color: #A0E7DB;
    padding: 2px;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 1px;
    border-radius: 2px;
    margin-right: 10px;
  }

</style>
