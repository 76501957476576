<template>
  <v-row
    align="center"
    justify="center"
    class="custom-v-row"
  >
    <v-col
      cols="12"
      md="10"
    >

    <v-card class="elevation-6">

      <div class = "instructions-title">
        <template v-if="fetchingCriteriaPage">
          <v-skeleton-loader
            type="list-item,list-item"
          ></v-skeleton-loader>
        </template>
        <template v-else>
          <p class = "survey-creator pt-4">
            <b>
              Criterio:
            </b>
            {{criteriaData.data.criterion.name}}
          </p>
          <v-btn
            v-if="!QSurveyIsFinished"
            small
            color="primary"
            style="position: absolute; right: 15px; top: 10px;"
            @click="finishDialog = true"
          >Finalizar encuesta</v-btn>
        </template>
      </div>

      <div class="container">

        <v-col v-if="showGraph" class="mb-12">
          <div style="text-align:center">
            Proporciones del vector propio
          </div>
          <div class= "container" style="position:relative;max-height:300px;max-width:300px">
            <pie-chart :options="chartOptions" :chart-data="chartData"/>
          </div>
          <div class="text-center">
            <span
              :class="crError ? 'red--text darken-2' : ''"
            >
              CR {{criteriaData.data.criterion.cr.value}}%
            </span>
            <br>
            <span>CR max {{criteriaData.data.criterion.cr.max}}%</span>
          </div>
        </v-col>

        <div v-if="fetchingCriteriaPage">

          <v-skeleton-loader
            type="list-item,list-item"
          ></v-skeleton-loader>
        </div>
        <div
          v-else
          class="mt-2 mb-6 comparisonContainer"
          :key="comparison.id"
          v-for="comparison in comparisons"
        >
          <div class="comparisonVariablesButtons">
            <v-btn
              class="pa-2 button-comparable"
              @click="changeAMajorThanB(comparison, true)"
              :loading="comparison.majorThanLoading"
              :outlined="comparison.a_major_than_b !== true"
              :color="comparison.a_major_than_b === true ? 'primary' : 'info'"
              :disabled="QSurveyIsFinished"
            >
              <span class="d-inline-block text-truncate" style="max-width: 100%; font-size: 12px">
                <template v-if="!!comparison.category_a">
                  {{ comparison.category_a }}
                </template>
                <template v-else>
                  {{ comparison.comparable_a.description }}
                </template>
              </span>
            </v-btn>
            <v-btn
              class="pa-2 ml-5 button-comparable"
              @click="changeAMajorThanB(comparison, false)"
              :loading="comparison.majorThanLoading"
              :outlined="comparison.a_major_than_b !== false"
              :color="comparison.a_major_than_b === false ? 'info' : 'primary'"
              :disabled="QSurveyIsFinished"
            >
              <span class="d-inline-block text-truncate" style="max-width: 100%; font-size: 12px">
                <template v-if="!!comparison.category_b">
                  {{ comparison.category_b }}
                </template>
                <template v-else>
                  {{ comparison.comparable_b.description }}
                </template>
              </span>
            </v-btn>
          </div>
          <div class="d-flex flex-column align-center magnitudeComponent">
            <v-rating
              :value="comparison.magnitude"
              @input="changeMagnitude(comparison, $event)"
              :readonly="comparison.magnitudeLoading || comparison.a_major_than_b === null"
              :length="9"
              class="d-flex"
            >
              <template v-slot:item="item">
                <v-btn
                  :disabled="QSurveyIsFinished"
                  @click="item.click"
                  icon
                  :style="{
                    cursor: comparison.magnitudeLoading
                      || comparison.a_major_than_b === null ? 'not-allowed' : 'pointer',
                    filter: comparison.magnitudeLoading
                      || comparison.a_major_than_b === null ? 'grayscale(1)' : 'grayscale(0)',
                    transition: 'all ease 1s',
                  }"
                  v-ripple="{ center: true }"
                  :class="{
                    'mr-3': item.index !== 8
                  }"
                >
                  <div style="margin-right: 2px;">
                    <template v-if="!item.isFilled">
                      <v-icon color="primary" x-large>
                        mdi-checkbox-blank-circle-outline
                      </v-icon>
                    </template>
                    <template v-if="item.isFilled">
                      <v-icon color="primary" x-large>
                        mdi-checkbox-blank-circle
                      </v-icon>
                    </template>
                  </div>
                  <div style="position: absolute;
                    inset: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                    <span
                      :class="{
                        'white--text': item.isFilled,
                      }"
                      style="
                        pointer-events: none;
                        margin-right: 1px;
                        font-size: 1.1rem;
                    ">
                      {{ item.index + 1 }}
                    </span>
                  </div>
                </v-btn>
              </template>
            </v-rating>
            <p>{{ getComparisonText(comparison.magnitude) }}</p>
          </div>
          <v-divider class="dividerComponent" style="flex: 1 0 100%" />
        </div>
        <div class="d-flex justify-center align-center mt-6 mb-2">
            <v-pagination
              :value="currentPage"
              @input="pageChange"
              :length="total"
            />
        </div>
      </div>
    </v-card>
    </v-col>

    <v-dialog
      v-model="finishDialog"
      max-width="450px"
    >
      <v-card>
        <v-card-title class="headline text-center">
          ¿Desea finalizar la encuesta?
        </v-card-title>

        <v-card-text class="text-justify">
          No podra posteriormente editar las respuestas.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="finishDialog = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="finishSurvey()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>

import { mapFields } from 'vuex-map-fields';
import Swal from 'sweetalert2';
import PieChart from '@/components/PieChart.vue';

export default {
  name: 'QuantificationRunSurvey',
  components: {
    PieChart,
  },
  data: () => ({
    currentPage: 1,
    criteriaData: null,
    value: 1,
    comparisons: [],
    total: 1,
    finishDialog: false,

    fetchingCriteriaPage: true,

    chartOptions: {
      legend: {
        display: false,
      },
    },

    chartData: {
      labels: [],
      datasets: [
        {
          backgroundColor: [
            '#ebac23',
            '#b80058',
            '#008cf9',
            '#006e00',
            '#00bbad',
            '#d163e6',
            '#b24502',
            '#ff9287',
            '#5954d6',
            '#00c6f8',
            '#878500',
            '#00a76c',
            '#bdbdbd',
          ],
          data: [],
        },
      ],
    },
  }),
  async mounted() {
    this.surveyStarted = true;
    await this.fetchCriteriaPage(this.currentPage);
    this.fetchingCriteriaPage = false;
  },
  computed: {
    ...mapFields({
      QSurveyStarted: 'QSurveyStarted',
      QSurveyIsFinished: 'QSurveyIsFinished',
    }),
    crError() {
      return this.criteriaData.data.criterion.cr.value > this.criteriaData.data.criterion.cr.max;
    },
    comparisonFinished() {
      return ![null, undefined].includes(this?.criteriaData?.data?.criterion?.alternatives);
    },
    showGraph() {
      return !this.fetchingCriteriaPage && this.comparisonFinished;
    },
  },
  methods: {
    async pageChange(page) {
      this.currentPage = page;
      this.fetchingCriteriaPage = true;
      await this.fetchCriteriaPage();
      this.fetchingCriteriaPage = false;
    },
    async finishSurvey() {
      this.finishDialog = false;
      try {
        this.axios
          .post(`/api/variable-surveys/${this.$route.params.id}/send`)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire(
                'Terminada',
                'Se ha enviado la encuesta correctamente',
                'success',
              ).then(() => {
                this.$router.push({ name: 'QuantificationInstructions' });
              });
            }
          })
          .catch((response) => {
            if (response.response.status === 422) {
              Swal.fire(
                'Error de consistencia',
                `Hay inconsistencias en las respuestas,
                deben ser resueltas antes de enviar la encuesta`,
                'warning',
              );
            } if (response.response.status === 409) {
              Swal.fire(
                'Error de envio',
                'La encuesta ya habia sido enviada anteriormente',
                'warning',
              );
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    async updateComparison(comparison) {
      try {
        const response = await this.axios
          .put(`/api/variable-surveys/${this.$route.params.id}/comparison/${comparison.id}`, {
            magnitude: comparison.magnitude,
            a_major_than_b: comparison.a_major_than_b,
          });

        this.$set(this.criteriaData.data.criterion, 'cr', response.data.cr);
        this.$set(this.criteriaData.data.criterion, 'alternatives', response.data?.category_vp ?? response.data?.vp);
        this.setGraphData(response.data.data.criterion.alternatives);

        console.log(response);
      } catch (error) {
        console.error(error);
      }
    },
    async changeAMajorThanB(comparison, value) {
      const oldValue = comparison.a_major_than_b;
      comparison.a_major_than_b = value;
      comparison.majorThanLoading = true;

      try {
        const response = await this.axios
          .put(`/api/variable-surveys/${this.$route.params.id}/comparison/${comparison.id}`, {
            a_major_than_b: comparison.a_major_than_b,
          });

        if (response?.data?.data?.cr) {
          const alternatives = response.data.data.category_vp ?? response.data.data.vp;
          this.$set(this.criteriaData.data.criterion, 'cr', response.data.data.cr);
          this.$set(this.criteriaData.data.criterion, 'alternatives', alternatives);
          this.setGraphData(alternatives);
        } else {
          console.log(response.data);
        }

        comparison.majorThanLoading = false;
        console.log(response);
      } catch (error) {
        console.error(error);
        comparison.a_major_than_b = oldValue;
        comparison.majorThanLoading = false;
      }
    },
    async changeMagnitude(comparison, value) {
      const oldValue = comparison.magnitude;
      comparison.magnitude = value;
      comparison.magnitudeLoading = true;

      try {
        const response = await this.axios
          .put(`/api/variable-surveys/${this.$route.params.id}/comparison/${comparison.id}`, {
            magnitude: comparison.magnitude,
          });

        if (response?.data?.data?.cr) {
          const alternatives = response.data.data.category_vp ?? response.data.data.vp;
          this.$set(this.criteriaData.data.criterion, 'cr', response.data.data.cr);
          this.$set(this.criteriaData.data.criterion, 'alternatives', alternatives);
          this.setGraphData(alternatives);
        }

        comparison.magnitudeLoading = false;
      } catch (error) {
        console.error(error);
        comparison.magnitude = oldValue;
        comparison.magnitudeLoading = false;
      }
    },
    getChangingComparison(newArray, oldArray) {
      for (let i = 0; i < newArray.length; i += 1) {
        try {
          Object.keys(newArray[i]).forEach((key) => {
            if (newArray[i][key] !== oldArray[i][key]) throw JSON.stringify(newArray[i]);
          });
        } catch (object) {
          return JSON.parse(object);
        }
      }
    },
    arrayDiff(a, b) {
      return [
        ...a.filter(x => !b.includes(x)),
        ...b.filter(x => !a.includes(x)),
      ];
    },
    roundNumber(number, places) {
      return parseFloat(`${Math.round(`${number}e+${places}`)}e-${places}`);
    },
    setGraphData(alternatives, resetData = true) {
      console.log('set graph data');
      if (resetData) {
        this.chartData = {
          labels: [],
          datasets: [
            {
              backgroundColor: [
                '#ebac23',
                '#b80058',
                '#008cf9',
                '#006e00',
                '#00bbad',
                '#d163e6',
                '#b24502',
                '#ff9287',
                '#5954d6',
                '#00c6f8',
                '#878500',
                '#00a76c',
                '#bdbdbd',
              ],
              data: [],
            },
          ],
        };
      }

      for (let i = 0; i < alternatives.length; i += 1) {
        this.chartData.datasets[0].data.push(
          this.roundNumber(this.roundNumber(alternatives[i].weight, 4) * 100, 2),
        );
        this.chartData.labels.push(
          alternatives[i].description,
        );
      }
    },
    async fetchCriteriaPage() {
      try {
        const response = await this.axios
          .get(`/api/variable-surveys/${this.$route.params.id}/page/${this.currentPage}`);

        this.$set(this, 'criteriaData', response.data);

        console.log(response?.data?.data?.criterion?.alternatives);

        if (response?.data?.data?.criterion?.alternatives) {
          this.setGraphData(response.data.data.criterion.alternatives);
        }

        const comparisons = response.data.data.comparisons.map(v => ({
          ...v,
          majorThanLoading: false,
          magnitudeLoading: false,
        }));

        this.total = response.data.metadata.total;

        this.$set(this, 'comparisons', comparisons);
        console.log(this.comparisons);
      } catch (error) {
        console.error(error);
      }
    },
    next(n, a) {
      console.log(n, a);
      console.log(this.page);
    },

    getComparisonText(value) {
      switch (value) {
        case 1:
          return 'Igual importancia';
        case 2:
        case 3:
          return 'Importancia moderada';
        case 4:
        case 5:
          return 'Importancia grande';
        case 6:
        case 7:
        case 8:
          return 'Importancia muy grande';
        case 9:
          return 'Importancia extrema';
        default:
          return '';
      }
    },
  },

};
</script>

<style>

.button-comparable {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 7%;
}

button.v-btn[disabled] {
  opacity: 1;
}

.dividerComponent {
  grid-area: divider;
}

.comparisonVariablesButtons {
  display: grid;
  grid-area: variableButtons;
  grid-template-columns: 50% 50%;
}

.magnitudeComponent {
  grid-area: magnitude;
}

.comparisonContainer {
  display: grid;
  grid-template-areas: "variableButtons magnitude"
                       "divider divider";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 48px;
}

b {
  color: #5C5C5C;
  font-weight: bold;
}

.start-button {
  margin-top: 3%;
  text-align: center;
}

div.instructions-title {
  margin-top: 1em;
  text-align: center;
  background-color: #E8E8E8;
  width: 100%;
  position: relative;
}

p.project-name {
  padding-top: 10px;
  color: #5C5C5C;
  font-weight: bold;
}

p.survey-creator {
  padding-bottom: 10px;
}

p.section-title {
  color: #5C5C5C;
  font-weight: bold;
}

p.paragraph {
  text-align: justify;
}

button {
    outline: none!important;
}

</style>
