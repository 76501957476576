<template>
  <svg
    class="parentToChildrenLine"
    width="1"
    style="z-index: 2; position: absolute; pointer-events: none;"
    :style="{
      left: left+'px',
      top: '80px',
      width: '2px',
      'transform-origin': 0,
      height: 'inherit',
    }"
    stroke="black"
  >
    <path
      stroke-dasharray="5, 2"
      stroke-width="1"
      d="M 0 23 L 0 0"
      fill="none"
    />
  </svg>
</template>

<script>
export default {
  name: 'ParentToChildrenLine',
  props: {
    left: Number,
  },
};
</script>
