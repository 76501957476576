<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :temporary="temporaryDrawer"
      app
    >
      <v-list
        v-if="token"
        dense
      >
        <v-list-item
          link
          color="purple"
        >
          <v-list-item-avatar color="primary">
            <span class="white--text headline">{{ user.name.toUpperCase()[0] }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="user.email" />
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item to="/" @click.stop="drawer = !drawer">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Proyectos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/variables" @click.stop="drawer = !drawer">
          <v-list-item-action>
            <v-icon>mdi-variable</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Variables</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/contactos" @click.stop="drawer = !drawer">
          <v-list-item-action>
            <v-icon>mdi-card-account-mail</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Contacto</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="logout"
          @click.stop="drawer = !drawer"
        >
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Cerrar Sesion</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list
        v-if="!token"
        dense
      >
        <v-list-item
          link
          to="/login"
          @click.stop="drawer = !drawer"
        >
          <v-list-item-action>
            <v-icon>mdi-key-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Iniciar sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/registrar"
          @click.stop="drawer = !drawer"
        >
          <v-list-item-action>
            <v-icon>mdi-file-document-edit</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Registrarse</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="primary"
      dark
      dense
      :height="appBarSize"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        SIM[ON]
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view
        v-if="loaded"
        id="routerComponent"
        :class="{ authenticated: authenticatedView }"
        @logout="attemptLogout"
      />
    </v-main>
  </v-app>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'App',
  data: () => ({
    loaded: false,
  }),
  computed: {
    ...mapFields([
      'token',
      'user',
      'drawer',
      'temporaryDrawer',
      'appBarSize',
    ]),
    authenticatedView() {
      return this.$route.matched.some(record => !record.meta.onlyNoAuth);
    },
  },
  mounted() {
    if (this.token) {
      this.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    }

    this.loaded = true;
  },
  methods: {
    attemptLogout() {
      this.axios.post('/api/logout')
        .then(() => {
          this.logout();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.logout();
          }
        });
    },
    logout() {
      this.token = '';
      this.user = null;
      this.axios.defaults.headers.common.Authorization = '';
      this.$router.push('/login');
    },
  },
};
</script>

<style>
  html {
    overflow: hidden!important;
  }

  #routerComponent {
    overflow: hidden;
    height: 100%;
  }

  #routerComponent.authenticated {
    max-height: 95vh;
    overflow: auto;
  }
</style>
