<template>
  <div></div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  name: 'VerifyEmail',
  data: () => ({
    loading: false,
  }),
  computed: {
    isAuth() {
      return this.$store.getters.isAuth;
    },
  },
  mounted() {
    this.loading = true;
    const url = new URL(window.location.href);
    const expires = url.searchParams.get('expires');
    const signature = url.searchParams.get('signature');
    const { id, hash } = this.$route.params;

    console.log('dispatching verifyEmail');
    this.$store.dispatch('verifyEmail', {
      expires,
      signature,
      id,
      hash,
    })
      .then(() => {
        this.loading = false;

        Swal.fire({
          title: '¡Listo!',
          text: 'El correo electrónico ha sido verificado exitosamente.',
          icon: 'success',
          confirmButtonText: 'Aceptar',
        });

        if (this.isAuth) {
          this.$router.push('/').catch(() => {});
        } else {
          this.$router.push({ name: 'login' }).catch(() => {});
        }
      })
      .catch((err) => {
        this.loading = false;
        if (err?.response?.status === 403) {
          Swal.fire({
            title: '¡Error!',
            text: 'El link es inválido o el usuario a verificar no es el mismo.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
          this.$router.push({ name: 'Unverified' }).catch(() => {});
        } else {
          Swal.fire({
            title: '¡Error!',
            text: 'Este servicio se encuentra bajo mantenimiento temporal.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        }
      });
  },
};
</script>
