<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-main>
      <v-row
        align="center"
        justify="center"
        style="height: 100%;"
      >
        <v-col
          cols="12"
          sm="8"
          :md="drawer ? '6' : '4'"
        >
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>Iniciar sesión</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <ValidationObserver
                ref="obs"
              >
                <v-form
                  id="login-form"
                  ref="loginForm"
                  @submit.prevent="attemptLogin"
                >
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="email"
                    rules="required|email|max:30"
                  >
                    <v-text-field
                      v-model="email"
                      label="Email"
                      :error-messages="errors"
                      :success="valid"
                      name="login"
                      prepend-inner-icon="mdi-at"
                      type="text"
                      outlined
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="password"
                    rules="required|max:30|min:8"
                  >
                    <v-text-field
                      id="password"
                      v-model="password"
                      label="Contraseña"
                      :error-messages="errors"
                      :success="valid"
                      name="password"
                      prepend-inner-icon="lock"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      outlined
                    />
                  </ValidationProvider>
                </v-form>
              </ValidationObserver>
            </v-card-text>
            <v-card-actions>
              <div class="d-flex flex-column" style="width: 100%">
                <div>
                  <v-btn
                    text
                    small
                    color="secondary"
                    to="/forgot-password"
                  >
                    ¿Olvidó la contraseña?
                  </v-btn>
                </div>
                <div class="d-flex">
                  <v-btn
                    to="/registrar"
                    text
                    color="primary"
                  >
                    Registrate
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :loading="loading"
                    type="submit"
                    form="login-form"
                    @click="attemptLogin"
                  >
                    Entrar
                  </v-btn>
                </div>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';
import { mapFields } from 'vuex-map-fields';
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';

export default {
  name: 'Login',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    email: '',
    password: '',
    loading: false,
    showPassword: false,
  }),
  computed: {
    ...mapFields([
      'token',
      'user',
      'drawer',
    ]),
  },
  methods: {
    attemptLogin() {
      if (this.loading) {
        return null;
      }

      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        this.axios.post('/api/login', {
          email: this.email,
          password: this.password,
        })
          .then(({ data: { token, user } }) => {
            this.loading = false;
            this.token = token;
            this.user = user;
            this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            this.$router.push('/');
          })
          .catch((error) => {
            this.loading = false;

            if (error.response.status !== 422) {
              Swal.fire({
                icon: 'error',
                title: 'Lo sentimos!',
                text: 'Ha ocurrido un error inesperado.',
              });
            } else {
              this.$refs.obs.setErrors(error.response.data.errors);
            }
          });
      }
    },
  },
};
</script>
