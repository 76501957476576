<template>
  <v-dialog
    :key="showDialog"
    v-model="showDialog"
    max-width="1000px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ cardTitle }}</span>
      </v-card-title>
      <v-card-text>
        <ValidationObserver
          ref="obs"
        >
          <v-form ref="variableForm">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="name"
              rules="required"
            >
              <v-text-field
                v-model="name"
                label="Nombre"
                :error-messages="errors"
                :success="valid"
              />
            </ValidationProvider>
            <v-textarea
              v-model="description"
              name="Description"
              label="Descripcion"
              :rows="2"
              auto-grow
            />
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="type"
              :rules="`required|oneOf:cualitativa,cuantitativa`"
            >
              <v-select
                v-model="type"
                :items="[
                  {text: 'Cualitativa', value: 'cualitativa'},
                  {text: 'Cuantitativa', value: 'cuantitativa'}
                ]"
                label="Tipo"
                :error-messages="errors"
                :success="valid"
                @change="property = ''"
                class="mb-2"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="property"
              :rules="`required|oneOf:libre,inversa,directa,restringida`"
            >
              <v-select
                v-model="property"
                :items="getPropertiesAvailable()"
                label="Propiedad"
                :error-messages="errors"
                :success="valid"
                class="mb-2"
                :disabled="!typeIsSelected"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="clasifications"
              :rules="getRulesForComboboxClasifications()"
            >
              <v-combobox
                clearable
                v-model="formattedClasifications"
                label="Categorias (solo cualitativas restringidas)"
                multiple
                chips
                :error-messages="errors"
                :dense="clasificationsEmpty"
                :disabled="!variableIsRestrictedQualitative"
              >
                <template v-slot:selection="{ attrs, item }">
                  <v-chip
                    small
                    v-bind="attrs"
                    close
                    @click:close="removeClasification(item)"
                  >
                    {{ item }}
                  </v-chip>
                </template>
              </v-combobox>
            </ValidationProvider>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="showDialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="submitForm"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
import Swal from 'sweetalert2';
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';

const mustBeCool = value => value.includes('cool');

export default {
  name: 'VariableForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    name: '',
    description: '',
    type: '',
    property: '',
    clasifications: [],
    loading: false,
  }),
  validations() {
    return {
      clasifications: {
        mustBeCool,
      },
    };
  },
  computed: {
    cardTitle() {
      return this.item === null
        ? 'Crear nueva variable'
        : `Editar Variable #${this.item.id}`;
    },
    clasificationsEmpty() {
      return this.clasifications.length < 1;
    },
    formattedClasifications: {
      get() {
        return this.variableIsRestrictedQualitative ? this.clasifications : [];
      },
      set(val) {
        this.clasifications = val;
      },
    },
    variableIsRestrictedQualitative() {
      return this.type === 'cualitativa' && this.property === 'restringida';
    },
    typeIsSelected() {
      return this.type !== '';
    },
    showDialog: {
      get() {
        return this.show || this.dialog;
      },
      set(val) {
        if (val === false) {
          this.resetForm();
          this.$emit('close');
        }
        this.dialog = val;
      },
    },
  },
  watch: {
    item() {
      if (this.item !== null) {
        this.name = this.item.name;
        this.description = this.item.description;
        this.type = this.item.type;
        this.property = this.item.property;
        this.clasifications = this.item.categories ?? [];
      }
    },
  },
  methods: {
    twoElements(value) {
      return value.length >= 2 ? true : 'Debe tener 2 elementos';
    },
    getRulesForComboboxClasifications() {
      return this.variableIsRestrictedQualitative ? 'required|minLength:2' : '';
    },
    getPropertiesAvailable() {
      if (this.type === 'cualitativa') {
        return [
          { text: 'Libre', value: 'libre' },
          { text: 'Restringida', value: 'restringida' },
        ];
      } if (this.type === 'cuantitativa') {
        return [
          { text: 'Inversa', value: 'inversa' },
          { text: 'Directa', value: 'directa' },
        ];
      } return [];
    },
    removeClasification(clasification) {
      this.formattedClasifications = this.formattedClasifications
        .filter(cl => cl !== clasification);
    },
    submitForm() {
      if (this.loading) {
        return null;
      }

      this.$refs.obs.validate()
        .then((valid) => {
          if (valid) {
            if (this.item) {
              this.update();
            } else {
              this.create();
            }
          }
        });
    },
    resetForm() {
      this.name = '';
      this.description = '';
      this.type = '';
      this.property = '';
      this.clasifications = [];
      this.$refs.obs.reset();
    },
    create() {
      const payload = {
        name: this.name,
        description: this.description,
        type: this.type,
        property: this.property,
      };

      if (this.variableIsRestrictedQualitative) {
        payload.categories = this.clasifications;
      }

      this.axios.post('/api/variables', payload)
        .then((response) => {
          Swal.fire({
            title: 'Excelente',
            text: response.data.message,
            icon: 'success',
          })
            .then(() => {
              this.showDialog = false;
              this.$emit('updateList');
              this.resetForm();
            });
        })
        .catch(() => {
          Swal.fire({
            title: 'Lo sentimos!',
            text: 'Ha ocurrido algun error.',
            icon: 'error',
          });
        });
    },
    update() {
      const payload = {
        name: this.name,
        description: this.description,
        type: this.type,
        property: this.property,
      };

      if (this.variableIsRestrictedQualitative) {
        payload.categories = this.clasifications;
      }

      this.axios.put(`/api/variables/${this.item.id}`, payload)
        .then((response) => {
          Swal.fire({
            title: 'Excelente',
            text: response.data.message,
            icon: 'success',
          })
            .then(() => {
              this.showDialog = false;
              this.$emit('updateList');
              this.resetForm();
            });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            Swal.fire({
              title: 'Lo sentimos!',
              text: error.response.data.message,
              icon: 'error',
            });
          } else {
            Swal.fire({
              title: 'Lo sentimos!',
              text: 'Ha ocurrido algun error.',
              icon: 'error',
            });
          }
        });
    },
  },
};
</script>
