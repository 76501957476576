<template>
  <div class="fill-height">
    <v-toolbar>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="showContactForm = true"
            :disabled="mainSurveysWereProcessed"
          >
            <v-icon>mdi-account-plus</v-icon>
          </v-btn>
        </template>
        <span>Crear e importar contacto</span>
      </v-tooltip>

      <v-divider vertical></v-divider>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="showImportContactForm = true"
            :disabled="mainSurveysWereProcessed"
          >
            <v-icon>mdi-account-arrow-right</v-icon>
          </v-btn>
        </template>
        <span>Importar contacto existente</span>
      </v-tooltip>

      <v-divider vertical></v-divider>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="!mainSurveysWereProcessed && !allSurveysFinished"
            @click="openLockUnlockSurveysDialog"
          >
            <v-icon v-if="!mainSurveysWereProcessed">mdi-lock</v-icon>
            <v-icon v-else>mdi-lock-open</v-icon>
          </v-btn>
        </template>
        <span>{{ lockMessage }}</span>
      </v-tooltip>
    </v-toolbar>

    <v-row no-gutters style="height: calc(100% - 74px)">
      <v-col md="12">
        <div id="tree-parent">
          <template>
            <v-container fluid class="fill-height">
              <v-content>
                <v-row align="center" justify="center">
                  <v-col cols="12" md="10" style="max-width: 1500px">
                    <v-tabs v-model="tab">
                      <v-tab> Invitaciones a expertos </v-tab>
                      <v-tab :disabled="!mainSurveysWereProcessed">
                        Vector agregado normalizado
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-card class="elevation-12">
                          <v-data-table
                            :headers="headers"
                            :items="invitedUsers"
                            :items-per-page="5"
                            class="elevation-1"
                            no-data-text="No se ha invitado a ningun experto"
                            :loading="loadingContacts"
                            loading-text="Cargando expertos invitados"
                            :search="search"
                          >
                            <template v-slot:top>
                              <v-toolbar flat color="white">
                                <v-chip class="mt-2" color="info" outlined pill>
                                  Expertos invitados
                                </v-chip>
                                <v-text-field
                                  v-model="search"
                                  label="Busca a un experto invitado"
                                  class="mx-4 mt-8"
                                ></v-text-field>
                                <v-icon
                                  color="primary"
                                  class="button mt-6"
                                  @click="fetchInitData()"
                                >
                                  mdi-reload
                                </v-icon>
                              </v-toolbar>
                            </template>
                            <template v-slot:item.status="{ item }">
                              <v-chip
                                x-small
                                class="mr-5 white--text"
                                :color="getTableSatusColorByInvitedUser(item)"
                              >
                                {{ getTableSatusByInvitedUser(item) }}
                              </v-chip>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-icon
                                v-if="
                                  !individualSurveyIsFinished(item) && !mainSurveysWereProcessed
                                "
                                small
                                color="#F99191"
                                class="mr-2"
                                @click="uninviteUser(item)"
                              >
                                delete
                              </v-icon>
                              <v-icon
                                small
                                color="gray"
                                class="mr-2"
                                @click="setSurveyLinkModal(item)"
                              >
                                mdi-link
                              </v-icon>
                              <v-icon
                                color="#5EBEB7"
                                class="mr-2"
                                small
                                @click="setSurveyStatusModal(item)"
                              >
                                mdi-eye
                              </v-icon>
                              <v-icon
                                v-if="resultModalAvailable(item)"
                                color="#4EB653"
                                small
                                @click="setSurveyResultsModal(item)"
                              >
                                mdi-table
                              </v-icon>
                              <v-icon
                                v-if="
                                  !individualSurveyIsFinished(item) && !mainSurveysWereProcessed
                                "
                                small
                                @click="() => resendInvitation(item)"
                              >
                                mdi-email-sync
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item>
                        <vagn-table ref="vagnTable"> </vagn-table>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </v-content>
            </v-container>
          </template>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="lockDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline text-center"> ¿Desea finalizar las encuestas? </v-card-title>
        resend icon
        <v-card-text class="text-justify">
          Si ya has culminado con la asignacion de encuestas, puedes bloquearlas y pasar a la
          siguiente etapa del proyecto, correspondiente a la seccion de calculos finales.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="lockDialog = false"> Cancelar </v-btn>

          <v-btn color="blue darken-1" text @click="finishSurveys()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unlockDialog" max-width="450px">
      <v-card>
        <v-card-title class="headline text-center">
          ¿Desea desbloquear las encuestas?
        </v-card-title>

        <v-card-text class="text-justify">
          Podras agregar o eliminar encuestas, pero todo el progreso en la etapa de cálculo será
          eliminado.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="unlockDialog = false"> Cancelar </v-btn>

          <v-btn color="blue darken-1" text @click="unlockSurveys"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <survey-link-modal
      :show="showSurveyLinkModal"
      @close="showSurveyLinkModal = false"
      ref="surveyLinkModal"
    />

    <survey-results-modal
      :show="showSurveyResultsModal"
      @close="showSurveyResultsModal = false"
      ref="surveyResultsModal"
    />

    <survey-status-modal
      :show="showSurveyStatusModal"
      @close="showSurveyStatusModal = false"
      ref="surveyStatusModal"
    />

    <contact-form
      :show="showContactForm"
      @close="showContactForm = false"
      :fromSurvey="true"
      @updateList="updateInvitedUsers"
    />

    <import-contact-form
      :show="showImportContactForm"
      @close="showImportContactForm = false"
      @updateList="updateInvitedUsers"
      ref="importContact"
    />
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { mapFields } from 'vuex-map-fields';
import ContactForm from '@/components/Contact/ContactForm.vue';
import ImportContactForm from '@/components/Contact/ImportContactForm.vue';
import SurveyStatusModal from '@/components/SurveyStatusModal.vue';
import SurveyLinkModal from '@/components/SurveyLinkModal.vue';
import SurveyResultsModal from '@/components/SurveyResultsModal.vue';
import VagnTable from '@/components/VagnTable.vue';

export default {
  name: 'Surveys',
  components: {
    ContactForm,
    ImportContactForm,
    SurveyStatusModal,
    SurveyLinkModal,
    SurveyResultsModal,
    VagnTable,
  },
  data: () => ({
    headers: [
      { text: 'Nombre', value: 'name', align: 'center' },
      { text: 'Apellido', value: 'lastname', align: 'center' },
      { text: 'Email', value: 'email', align: 'center' },
      {
        text: 'Estatus',
        value: 'status',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
    tab: 0,
    loadingContacts: false,
    lockDialog: false,
    lockSurveys: false,
    unlockDialog: false,
    showContactForm: false,
    showImportContactForm: false,
    showSurveyStatusModal: false,
    showSurveyResultsModal: false,
    showSurveyLinkModal: false,
    transparentImg: null,
    availableContacts: null,
    search: '',
    surveysInfo: null,
  }),
  async mounted() {
    this.transparentImg = new Image();
    this.transparentImg.src = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    this.fetchInitData();
  },
  computed: {
    ...mapFields({
      project: 'project',
      diagram: 'project.diagram',
      notInvitedUsers: 'notInvitedUsers',
    }),
    lockMessage() {
      return this.mainSurveysWereProcessed ? 'Desbloquear encuestas' : 'Bloquear encuestas';
    },
    invitedUsers() {
      if (this.availableContacts) {
        return this.availableContacts.filter(this.userIsInvited);
      }
      return [];
    },
    mainSurveysWereProcessed() {
      if (this.project && 'vp' in this.project) {
        return JSON.parse(this.project.vp).length > 0;
      }
      return false;
    },
    allSurveysFinished() {
      if (this.surveysInfo) {
        if (this.surveysInfo.data.length === 0) {
          return false;
        }
        return !this.surveysInfo.data.some(survey => survey.finished_at === null);
      }
      return false;
    },
  },
  methods: {
    openLockUnlockSurveysDialog() {
      if (this.mainSurveysWereProcessed) {
        this.unlockDialog = true;
      } else {
        this.lockDialog = true;
      }
    },
    async resendInvitation(item) {
      const survey = this.surveysInfo.data.find(s => s.contact_id === item.id);
      try {
        await this.axios.post(`/api/surveys/${survey.token}/resend-email`);
        await this.fetchInitData();

        Swal.fire({
          title: '¡Éxito!',
          text: 'Se ha reenviado el email de invitación',
          icon: 'success',
          confirmButtonText: 'Aceptar',
        });
      } catch (error) {
        Swal.fire({
          title: '¡Error!',
          text: 'No se pudo reenviar el email de invitación',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
        console.log(error);
      }
    },
    reFetchProjectInfo() {
      this.loading = true;
      return this.axios
        .get(`/api/projects/${this.$route.params.id}`)
        .then(({ data }) => {
          this.$set(this, 'project', {
            ...data,
            diagram: JSON.parse(data.diagram),
          });
          this.loading = false;
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            this.message = 'Proyecto no encontrado';
            this.snackbar = true;
            this.$router.push('/');
          }
        });
    },
    async fetchInitData() {
      this.loadingContacts = true;
      await this.fetchProjectSurveyInfo();
      await this.loadAvailableContacts();
      this.$refs.importContact.setSurveyInfo(this.notInvitedUsers);
    },
    async finishSurveys() {
      this.lockDialog = false;

      try {
        this.axios
          .post(`/api/projects/${this.$route.params.id}/finish_surveys`)
          .then((response) => {
            if ([200, 204, 201].includes(response.status)) {
              Swal.fire(
                'Terminada',
                `Se han finalizado todas las encuestas
                 y se ha calculado el vector agregado`,
                'success',
              ).then(() => {
                this.reFetchProjectInfo();
              });
            }
          })
          .catch((response) => {
            if (response.response.status === 409) {
              Swal.fire('Error de finalizacion', response.response.data.message, 'warning');
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    async unlockSurveys() {
      try {
        this.unlockDialog = false;
        this.axios
          .post(`/api/projects/${this.$route.params.id}/unlock_surveys`)
          .then((response) => {
            if ([200, 204, 201].includes(response.status)) {
              Swal.fire('Desbloqueado', 'Se han desbloqueado todas las encuestas', 'success').then(
                () => {
                  this.reFetchProjectInfo();
                },
              );
            }
          })
          .catch((response) => {
            if (response.response.status === 409) {
              Swal.fire('Error de desbloqueo', response.response.data.message, 'warning');
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    resultModalAvailable(user) {
      return (
        this.surveysInfo.data.find(survey => survey.contact_id === user.id).status === 'finished'
      );
    },
    getInviteLinkByUser(user) {
      return this.surveysInfo.data.find(survey => survey.contact_id === user.id).id;
    },
    getTableSatusColorByInvitedUser(item) {
      if (this.surveysInfo) {
        switch (this.surveysInfo.data.find(survey => survey.contact_id === item.id).status) {
          case 'not-started':
            return 'blue';
          case 'in-progress':
            return 'orange';
          case 'finished':
            return 'green';
          default:
            return '';
        }
      }
      return '';
    },
    individualSurveyIsFinished(item) {
      if (this.surveysInfo) {
        if (
          this.surveysInfo.data.find(survey => survey.contact_id === item.id).status
          === 'finished'
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    getTableSatusByInvitedUser(item) {
      if (this.surveysInfo) {
        switch (this.surveysInfo.data.find(survey => survey.contact_id === item.id).status) {
          case 'not-started':
            return 'No iniciada';
          case 'in-progress':
            return 'En progreso';
          case 'finished':
            return 'Finalizada';
          default:
            return '';
        }
      }
      return '';
    },
    async updateInvitedUsers() {
      await this.fetchProjectSurveyInfo();
      await this.loadAvailableContacts();
      this.$refs.importContact.setSurveyInfo(this.availableContacts.filter(this.userIsNotInvited));
    },
    async updateNotInvitedUsers() {
      this.notInvitedUsers = this.availableContacts.filter(this.userIsNotInvited);
    },
    userIsInvited(user) {
      if (this.surveysInfo != null) {
        return this.surveysInfo.data.some(survey => survey.contact_id === user.id);
      }
    },
    userIsNotInvited(user) {
      if (this.surveysInfo != null) {
        return !this.surveysInfo.data.some(survey => survey.contact_id === user.id);
      }
    },
    async fetchProjectSurveyInfo() {
      try {
        const response = await this.axios.get(`/api/projects/${this.$route.params.id}/surveys`);

        this.$set(this, 'surveysInfo', response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async loadAvailableContacts() {
      try {
        const response = await this.axios.get('/api/contacts');

        this.$set(this, 'availableContacts', response.data);
        this.loadingContacts = false;
        await this.updateNotInvitedUsers();
      } catch (error) {
        console.error(error);
      }
    },
    setCurrentContact(item) {
      this.currentSelectedUser = item;
    },
    setSurveyStatusModal(user) {
      this.$refs.surveyStatusModal.setNecesaryInfo(user, this.surveysInfo);
      this.showSurveyStatusModal = true;
      this.currentSelectedUser = user;
    },
    setSurveyLinkModal(user) {
      this.$refs.surveyLinkModal.setNecesaryInfo(user, this.surveysInfo);
      this.showSurveyLinkModal = true;
      this.currentSelectedUser = user;
    },
    setSurveyResultsModal(user) {
      this.$refs.surveyResultsModal.setNecesaryInfo(user, this.surveysInfo);
      this.showSurveyResultsModal = true;
      this.currentSelectedUser = user;
    },
    async uninviteUser(user) {
      Swal.fire({
        title: '¿Estas seguro?',
        text: `Eliminar invitacion a encuesta de ${user.email}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            this.axios
              .delete(`/api/surveys/${this.getInviteLinkByUser(user)}`)
              .then((response) => {
                if (response.status === 204) {
                  Swal.fire(
                    'Eliminado!',
                    'Se ha eliminado la invitacion correctamente.',
                    'success',
                  ).then(() => {
                    this.loadingContacts = true;
                    this.updateInvitedUsers();
                  });
                }
              })
              .catch(() => {
                Swal.fire({
                  title: 'Lo sentimos!',
                  text: 'Ha ocurrido algun error.',
                  icon: 'error',
                });
              });
          } catch (error) {
            console.error(error);
          }
        }
      });
    },
  },
};
</script>

<style>
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: rgb(74 68 68 / 87%);
}

.children-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.child {
  flex: 1;
}

.red_list .v-list-item-group .v-list-item--active {
  background-color: red;
  color: white;
}

p.section-title {
  text-align: center;
}

.invite-button {
  text-align: center;
}

body {
  padding-right: 0 !important;
}
</style>
