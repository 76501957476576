<template>
  <div
    class="subtree-wrapper"
  >
    <div
      ref="parentNode"
      style="pointer: cursor;"
      :style="{
        border: border,
        backgroundColor: selected ? levelColor : '#fff',
      }"
      class="node elevation-6"
      @click="selectNode"
      @drag="onDrag"
      @dragstart="onDragStart"
      @dragend="onDragEnd"
      @drop="onDrop"
      @dragover="onDragOver"
      @dragenter="onDragEnter"
      @dragleave="onDragLeave"
      v-click-outside="{
        handler: deselectNode,
        include: include,
      }"
    >
      <div class="node-text">
        {{ subtree.name | abbreviate }}
      </div>
      <ChildrenLine
        v-if="hasChildren"
        :left="halfX"
        :top="height"
      />
      <ParentLine
        :left="halfX"
        :final-x="childToParentDistance"
      />
      <ChildrenHorizontalLine
        v-if="moreThanOneChildren"
        :children-line-start="childrenLineStart"
        :children-line-end="childrenLineEnd"
        :parent-left="nodeLeft"
      />
    </div>
    <div class="children-wrapper">
      <div
        v-for="(child, i) in children"
        :key="i"
        class="child"
      >
        <node
          :subtree="child"
          :parent-absolute-middle.sync="absoluteHalfX"
          :children-number="i"
          :level="level + 1"
        />
      </div>
    </div>
    <div
      id="dragItem"
      class="node elevation-12"
      ref="dragItem"
      :class="{ show: drag }"
    >
      <div class="node-text">
        {{ dragItemName }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import ChildrenLine from '@/components/ChildrenLine.vue';
import ParentLine from '@/components/ParentLine.vue';
import ChildrenHorizontalLine from '@/components/ChildrenHorizontalLine.vue';
import TreeColorLevels from '@/misc/treeColors';

export default {
  name: 'Node',
  components: {
    ChildrenLine,
    ParentLine,
    ChildrenHorizontalLine,
  },
  filters: {
    abbreviate(val) {
      return val?.length > 50
        ? `${val.slice(0, 10)}...`
        : val;
    },
  },
  props: {
    subtree: {
      type: Object,
    },
    parentAbsoluteMiddle: {
      type: Number,
    },
    childrenNumber: {
      type: Number,
    },
    level: {
      type: Number,
    },
  },
  data: () => ({
    halfX: 0,
    height: 0,
    absoluteHalfX: 0,
    interval: null,
    childrenLineStart: null,
    childrenLineEnd: null,
    nodeLeft: null,
    dragItemName: '',
    dragItemType: '',
    timer: null,
    delay: 20,
    drag: false,
    transparentImg: null,
    dragOver: false,
  }),
  computed: {
    ...mapFields({
      selectedNode: 'selectedNode',
    }),
    selected() {
      return this.selectedNode?.id === this.subtree.id;
    },
    children() {
      return this.subtree.children
        ? this.subtree.children
        : [];
    },
    hasChildren() {
      return this.children.length > 0;
    },
    moreThanOneChildren() {
      return this.children.length > 1;
    },
    childToParentDistance() {
      return this.parentAbsoluteMiddle - this.absoluteHalfX;
    },
    levelColor() {
      return TreeColorLevels[(this.level - 1) % TreeColorLevels.length];
    },
    border() {
      return this.dragOver
        ? `2px dashed ${this.levelColor}`
        : `2px solid ${this.levelColor}`;
    },
  },
  async mounted() {
    await this.$nextTick();
    this.transparentImg = new Image();
    this.transparentImg.src = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

    this.recalculate();

    if (this.interval) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
    this.interval = window.setInterval(async () => {
      await this.$nextTick();
      this.recalculate();
    }, 100);
  },
  beforeDestroy() {
    window.clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    onDrag(evt) {
      if (this.timer) return;

      this.timer = setTimeout(() => {
        this.timer = null;
        this.$refs.dragItem.style.left = `${evt.x - 55}px`;
        this.$refs.dragItem.style.top = `${evt.y - 25}px`;
      }, this.delay);
    },
    onDragStart(evt) {
      this.dragItemName = this.subtree.name;
      this.dragItemType = this.subtree.type;
      evt.dataTransfer.setData('text/plain', JSON.stringify({
        type: 'node',
        data: {
          name: this.subtree.name,
          type: this.subtree.type,
          children: [],
        },
      }));
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.setDragImage(this.transparentImg, 0, 0);
      this.drag = true;
      this.$refs.dragItem.style.left = `${evt.x - 55}px`;
      this.$refs.dragItem.style.top = `${evt.y - 25}px`;
      this.$refs.dragItem.style.display = 'block';
    },
    include() {
      return [...document.querySelectorAll('.nodeControl')];
    },
    onDragEnd() {
      this.dragItemName = '';
      this.dragItemType = '';
      this.drag = false;
      this.$refs.dragItem.style.display = 'none';
    },
    onDrop(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.dragOver = false;
      const data = JSON.parse(evt.dataTransfer.getData('text/plain'));

      if (data.type === 'node') {
        this.subtree.children.push(data.data);
        this.$store.dispatch('addNode', data.data.id);
      }
    },
    onDragOver(evt) {
      evt.preventDefault();
      evt.dataTransfer.dropEffect = 'move';
    },
    onDragEnter(evt) {
      evt.preventDefault();
      this.dragOver = true;
    },
    onDragLeave(evt) {
      evt.preventDefault();
      this.dragOver = false;
    },
    async recalculate() {
      const rect = this.$refs.parentNode.getBoundingClientRect();
      this.halfX = rect.width / 2;
      this.height = rect.height;
      this.absoluteHalfX = rect.left + this.halfX;
      this.nodeLeft = rect.left;

      if (this.moreThanOneChildren) {
        await this.$nextTick();
        const childrenNodes = this.$children.filter(v => v.$el.classList.contains('subtree-wrapper'));
        const firstChildren = childrenNodes[0].$el.getBoundingClientRect();
        const lastChildren = childrenNodes[childrenNodes.length - 1].$el.getBoundingClientRect();

        this.childrenLineStart = firstChildren.x + firstChildren.width / 2;
        this.childrenLineEnd = lastChildren.x + lastChildren.width / 2;
      }
    },
    selectNode() {
      if (this.selected) {
        this.$store.dispatch('deselectNode', this.subtree);
      } else {
        this.$store.dispatch('selectNode', this.subtree);
      }
    },
    deselectNode() {
      this.$store.dispatch('deselectNode', this.subtree.id);
    },
  },
};
</script>
