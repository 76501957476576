<template>
  <div class="fill-height">
    <v-tabs :loading="true">
      <v-icon
        class="ml-3 mr-3"
        @click="goBack"
      >
        mdi-arrow-left
      </v-icon>
      <v-tab
        v-for="(step, i) in steps"
        :key="i"
        :to="{ path: step.path, params: { id: $route.params.id } }"
        :disabled="step.locked"
      >
        {{ step.name }}
      </v-tab>
    </v-tabs>
    <router-view @reloadProjectData="loadProjectData"/>
    <v-snackbar
      v-model="snackbar"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ProjectViewer',
  data: () => ({
    loading: true,
    snackbar: false,
    message: '',
  }),
  computed: {
    ...mapFields({
      project: 'project',
      temporaryDrawer: 'temporaryDrawer',
      appBarSize: 'appBarSize',
    }),
    diagramIsLocked() {
      if (this.project) {
        return this.project.diagram_locked;
      } return false;
    },
    mainSurveysWereProcessed() {
      if (this.project && 'vp' in this.project) {
        return JSON.parse(this.project.vp).length > 0;
      } return false;
    },
    steps() {
      return [
        { name: 'Diagrama', path: 'diagrama', locked: false },
        { name: 'Encuestas', path: 'encuestas', locked: !this.diagramIsLocked },
        { name: 'Calculos', path: 'procesar', locked: !(this.diagramIsLocked && this.mainSurveysWereProcessed) },
        // { name: 'Reporte', path: 'reporte', locked: true },
      ];
    },
  },
  async mounted() {
    this.temporaryDrawer = true;
    this.appBarSize = 36;
    await this.loadProjectData();

    const params = { id: this.$route.params.id };

    if (this.$route.name === 'ProjectViewer') {
      switch (this.project.status) {
        case 1:
          this.$router.push({ name: 'Diagram', params });
          break;
        case 2:
          this.$router.push({ name: 'Surveys', params });
          break;
        case 3:
          this.$router.push({ name: 'Process', params });
          break;
        case 4:
          this.$router.push({ name: 'Report', params });
          break;
        default:
          this.$router.push('/projects');
          break;
      }
    }
  },
  methods: {
    loadProjectData() {
      this.loading = true;
      return this.axios
        .get(`/api/projects/${this.$route.params.id}`)
        .then(({ data }) => {
          this.$set(this, 'project', {
            ...data,
            diagram: JSON.parse(data.diagram),
          });
          this.loading = false;
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            this.message = 'Proyecto no encontrado';
            this.snackbar = true;
            this.$router.push('/');
          }
        });
    },
    goBack() {
      this.$router.push('/');
    },
  },
  beforeDestroy() {
    this.temporaryDrawer = false;
    this.appBarSize = null;
  },
};
</script>

<style>

</style>
