<template>
  <svg
    width="1"
    style="z-index: 2; position: absolute; pointer-events: none;"
    class="ChildrenHorizontalLine"
    :style="{
      left: left+'px',
      top: '102px',
      width: width+'px',
    }"
    stroke="black"
  >
    <line
      stroke-dasharray="5, 2"
      x1="0"
      :x2="width"
      y1="0"
      y2="0"
    />
  </svg>
</template>

<script>
export default {
  name: 'ChildrenHorizontalLine',
  props: {
    childrenLineStart: Number,
    childrenLineEnd: Number,
    parentLeft: Number,
  },
  computed: {
    width() {
      return this.childrenLineEnd - this.childrenLineStart;
    },
    left() {
      return this.childrenLineStart - this.parentLeft;
    },
  },
};
</script>
