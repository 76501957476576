<template>
  <div class="test">
    <Treant
      :tree="tree"
      :config="config"
    ></Treant>
  </div>
</template>

<script>

import Treant from '@scaife-viewer/vue-treant';

export default {
  name: 'Test',
  components: {
    Treant,
  },
  data: () => ({
    tree: {
      name: 'Variable interpretativa de nombre largo',
      children: [
        {
          name: 'b',
          children: [
            { name: 'd', children: [] },
            { name: 'e', children: [] },
            {
              name: 'f',
              children: [
                { name: 'i', children: [] },
                { name: 'j', children: [] },
              ],
            },
          ],
        },
        { name: 'z', children: [] },
        {
          name: 'c',
          children: [
            { name: 'g', children: [] },
            {
              name: 'h',
              children: [
                {
                  name: 'k',
                  children: [
                    { name: 'm', children: [] },
                    { name: 'n', children: [] },
                  ],
                },
                { name: 'l', children: [] },
              ],
            },
          ],
        },
      ],
    },
    config: {
      animateOnInit: true,
      connectors: {
        type: 'straight',
      },
      nodeStructure: {
        name: 'Parent node',
        children: [
          {
            name: 'First child',
          },
          {
            name: 'Second child',
          },
        ],
      },
    },
  }),
};
</script>

<style scoped>
  .test {
    height: 300px;
  }
</style>
