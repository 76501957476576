<template>
  <svg
    class="childrenLine"
    width="1"
    style="z-index: 2; position: absolute; pointer-events: none;"
    :style="{
      left: left+'px',
      top: top+'px',
    }"
    stroke="black"
  >
    <line
      stroke-dasharray="3,1"
      x1="0"
      x2="0"
      y1="0"
      y2="24"
    />
  </svg>
</template>

<script>
export default {
  name: 'ChildrenLine',
  props: {
    top: Number,
    left: Number,
  },
};
</script>
