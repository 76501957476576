<template>
  <v-dialog
    :key='showDialog'
    v-model='showDialog'
    max-width='800px'
  >
    <v-card>
      <v-card-title>
        <span class='headline mb-4 center'>
          Tabla de ponderacion final del experto
          <b v-if='currentSelectedUser'>
          {{currentSelectedUser.name}}
          {{currentSelectedUser.lastname}}
          </b>
        </span>
      </v-card-title>
      <v-card-text>
          <table class="results-table">
            <tbody v-if="!loading">
              <td v-for="i in countMaxDepth(this.diagram)*2 - 2" v-bind:key="i"></td>
              <td class="vp-tag">VP</td>
              <tr
                class="tr-variable"
                v-for="(trelement,index) in matrix"
                v-bind:key="generateRandomKeyForATr()"
              >
                <template v-for="tdelement in trelement">
                  <td v-bind:key="tdelement.id"
                      :rowspan="tdelement.leafs ? tdelement.leafs : 1"
                      :colspan="tdelement.colspan ? tdelement.colspan : 1"
                      class="td-pond-variable-name">
                    {{tdelement.name}}
                  </td>
                  <td v-bind:key="`${tdelement.id}-tdpond`"
                      :rowspan="tdelement.leafs ? tdelement.leafs : 1"
                      class="td-pond">
                    <template v-if="tdelement.weight">
                      {{roundNumber(tdelement.weight,4)}}
                    </template>
                    <template v-else>no pond</template>
                  </td>
                </template>
                <td
                  class="td-pond-vp-cell"
                  > {{roundNumber(surveyInfo.aggregated_vp[index],4)}}
                </td>
              </tr>
            </tbody>
            <v-skeleton-loader v-else
              type="table-row-divider@3"
            ></v-skeleton-loader>
          </table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color='red darken-1'
          text
          @click='showDialog = false'
        >
          cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type='text/javascript'>
import { mapFields } from 'vuex-map-fields';


export default {
  name: 'SurveyResultsModal',
  components: {
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    dialog: false,
    currentSelectedUser: null,
    surveyInfo: null,
    matrix: null,
    surveyTree: null,
    loading: false,
  }),
  mounted() {},
  methods: {
    generateRandomKeyForATr() {
      return (Math.random() + 1).toString(36).substring(2);
    },
    roundNumber(number, places) {
      return parseFloat(`${Math.round(`${number}e+${places}`)}e-${places}`);
    },
    countMaxDepth(root) {
      if (!root) return 0;
      let depth = 0;
      const queue = [];
      queue.push(root);

      while (queue.length) {
        const queueSize = queue.length;

        for (let i = 0; i < queueSize; i += 1) {
          const node = queue.shift();
          queue.push(...node.children);
        }

        depth += 1;
      }

      return depth;
    },
    countLeafs(tree) {
      let leafs = 0;
      const queue = [tree];

      while (queue.length > 0) {
        const pointer = queue.shift();
        if (pointer.children.length === 0) {
          leafs += 1;
        } else {
          for (let i = 0; i < pointer.children.length; i += 1) {
            queue.push(pointer.children[i]);
          }
        }
      }

      return leafs;
    },
    preorderTrasversal(diagram) {
      if (!diagram) return [];

      const maxDepth = this.countMaxDepth(diagram);

      const preorder = [];
      const stack = [];
      const altStack = [];

      stack.push(diagram);
      preorder.push(diagram);

      let par = null;

      while (stack.length > 0) {
        let flag = 0;
        if (stack[(stack.length - 1)].children.length === 0) {
          stack[stack.length - 1].colspan = (maxDepth - stack.length) * 2 + 1;
          altStack.push(stack.slice(0));
          stack.pop();
        } else {
          par = stack[stack.length - 1];
          stack[stack.length - 1].leafs = this.countLeafs(stack[stack.length - 1]);
        }

        for (let i = 0; i < par.children.length; i += 1) {
          if (preorder.indexOf(par.children[i]) === -1) {
            flag = 1;
            stack.push(par.children[i]);
            preorder.push(par.children[i]);
            break;
          }
        }

        if (flag === 0) stack.pop();
      }
      this.matrix = this.processArray(altStack);
      console.log(this.matrix);
    },
    processArray(RawPreorder) {
      const visited = [];
      for (let i = 0; i < RawPreorder.length; i += 1) {
        for (let j = 0; j < RawPreorder[i].length; j += 1) {
          if (visited.indexOf(RawPreorder[i][j]) > -1) {
            delete RawPreorder[i][j];
          }

          visited.push(RawPreorder[i][j]);
        }
        RawPreorder[i] = RawPreorder[i].filter(n => n);
      }
      RawPreorder[0].shift();
      return RawPreorder;
    },
    async setNecesaryInfo(user, surveysInfo) {
      this.loading = true;
      this.currentSelectedUser = user;
      this.surveyInfo = surveysInfo.data.find(s => s.contact_id === user.id);
      this.fetchSurveyTree(this.surveyInfo.token);
      this.loading = false;
    },
    async fetchSurveyTree(token) {
      this.fetchingSurveyInfo = true;
      try {
        const response = await this.axios
          .get(`/api/surveys/${token}`);

        this.preorderTrasversal(JSON.parse(response.data.data.surveyTree));
      } catch (error) {
        console.error(error);
      }
      this.fetchingSurveyInfo = false;
    },
  },
  computed: {
    ...mapFields({
      diagram: 'project.diagram',
    }),
    showDialog: {
      get() {
        return this.show || this.dialog;
      },
      set(val) {
        if (val === false) {
          this.$emit('close');
        }
        this.dialog = val;
      },
    },
  },
};
</script>

<style type='text/css'>

  li {
    display: inline;
  }

  table.results-table {
    border: 1px solid white;
    width: 100%;
  }

  td,tr {
    margin: 0;
    text-align: center;
  }

  td {
    padding: 5px;
  }

  td.vp-tag {
    font-weight: bold;
  }

  td.td-pond-vp-cell {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    background-color: #BED0FB;
    word-wrap: break-word;
  }

  td.td-pond {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    background-color: #DEDEDE;
    word-wrap: break-word;
  }

  td.td-pond-variable-name {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    background-color: #EEEEEE;
    word-wrap: break-word;
  }

  tr.tr-variable {
    background-color: #EEEEEE;
    word-wrap: break-word;
  }

</style>
