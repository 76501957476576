<template>
  <div
    id="tree"
    ref="parentContainer"
    class="subtree-wrapper"
  >
    <div
      ref="parentNode"
      class="node elevation-6"
      @click="test"
      :class="{
        'dragover': dragOver,
      }"
      @drop="onDrop"
      @dragenter="onDragEnter"
      @dragleave="onDragLeave"
      @dragover="onDragover"
    >
      <div class="node-text">
        {{ tree.name | abbreviate }}
      </div>

      <ChildrenLine
        v-if="false && hasChildren"
        :left.sync="halfX"
        :top.sync="height"
      />
      <ChildrenHorizontalLine
        v-if="moreThanOneChildren"
        :children-line-start="childrenLineStart"
        :children-line-end="childrenLineEnd"
        :parent-left="nodeLeft"
      />
      <ParentToChildrenLine
        v-if="hasChildren"
        :left.sync="halfX"
      />
    </div>
    <div class="children-wrapper">
      <div
        v-for="(child, i) in children"
        :key="i"
        class="child"
      >
        <subtree
          :subtree.sync="children[i]"
          :parent-absolute-middle.sync="absoluteHalfX"
          :children-number="i"
          :level="level + 1"
          @removeNode="removeNode($event, i)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Subtree from './Subtree.vue';
import ChildrenLine from './ChildrenLine.vue';
import ChildrenHorizontalLine from './ChildrenHorizontalLine.vue';
import ParentToChildrenLine from './ParentToChildrenLine.vue';

export default {
  name: 'Tree',
  components: {
    Subtree,
    ChildrenLine,
    ChildrenHorizontalLine,
    ParentToChildrenLine,
  },
  filters: {
    abbreviate(val) {
      return val?.length > 50
        ? `${val.slice(0, 10)}...`
        : val;
    },
  },
  props: {
    tree: {
      type: Object,
    },
  },
  data: () => ({
    halfX: 0,
    height: 0,
    absoluteHalfX: 0,
    internalValue: {
      name: '',
      children: [],
    },
    interval: null,
    childrenLineStart: null,
    childrenLineEnd: null,
    nodeLeft: null,
    level: 0,
    dragOver: false,
  }),
  computed: {
    children() {
      return this.internalValue.children
        ? this.internalValue.children
        : [];
    },
    hasChildren() {
      return this.children.length > 0;
    },
    moreThanOneChildren() {
      return this.children.length > 1;
    },
    childToParentDistance() {
      return 0;
    },
  },
  watch: {
    tree(val) {
      this.internalValue = Object.assign({}, val);
    },
  },
  async mounted() {
    await this.$nextTick();
    this.internalValue = Object.assign({}, this.tree);

    if (this.interval) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
    this.recalculate();

    this.interval = window.setInterval(() => this.recalculate(), 100);
  },
  beforeDestroy() {
    window.clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    onDrop(evt) {
      evt.preventDefault();
      this.dragOver = false;
      const data = JSON.parse(evt.dataTransfer.getData('text/plain'));

      if (data.type === 'node') {
        this.internalValue.children.push(data.data);
        this.$store.dispatch('addNode', data.data.id);
      }
    },
    onDragover(evt) {
      evt.preventDefault();
      evt.dataTransfer.dropEffect = 'move';
    },
    onDragEnter(evt) {
      evt.preventDefault();
      this.dragOver = true;
    },
    onDragLeave(evt) {
      evt.preventDefault();
      this.dragOver = false;
    },
    async recalculate() {
      const rect = this.$refs.parentNode.getBoundingClientRect();
      this.halfX = rect.width / 2;
      this.height = rect.height;
      this.absoluteHalfX = rect.left + this.halfX;
      this.nodeLeft = rect.left;

      if (this.moreThanOneChildren) {
        await this.$nextTick();
        const childrenNodes = this.$children.filter(v => v.$el.classList.contains('subtree-wrapper'));
        const firstChildren = childrenNodes[0].$el.getBoundingClientRect();
        const lastChildren = childrenNodes[childrenNodes.length - 1].$el.getBoundingClientRect();

        this.childrenLineStart = firstChildren.x + firstChildren.width / 2;
        this.childrenLineEnd = lastChildren.x + lastChildren.width / 2;
      }
    },
    removeNode(ev, index) {
      this.$emit('removeNode', ev.concat([index]));
    },
    test() {
      console.log(this.internalValue.name);
    },
  },
};
</script>

<style scoped>
  .node {
    border: 2px solid transparent;
  }

  .node.dragover {
    border: 2px dashed black;
  }
</style>
