<template>
  <div>
    diagrama final encuesta
  </div>
</template>

<script>
export default {
  name: 'SurveyDiagram',
};
</script>

<style>

</style>
