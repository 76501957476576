<template>
  <v-dialog
    :key="showDialog"
    v-model="showDialog"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline mb-4">Importar contacto</span>
      </v-card-title>
      <v-card-text>
        <ValidationObserver
          ref="obs"
        >
          <v-form ref="contactForm">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="name"
              rules="required"
            >
              <v-autocomplete
                v-model="selectedUser"
                :disabled="false"
                :items="notInvitedUsers"
                item-text="name"
                color="blue"
                hide-no-data
                hide-selected
                label="Busca un contacto que importar"
                return-object
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.name }} {{ data.item.lastname }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.lastname }}, {{ data.item.name }} ({{data.item.email}})
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="showDialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="inviteUser"
          :disabled="importButtonDisabled"
        >
          Importar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">

import Swal from 'sweetalert2';
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';

export default {
  name: 'ImportContactForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    contacts: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    selectedUser: null,
    email: '',
    loading: false,
    isQuantificationSurvey: false,
  }),
  mounted() {},
  computed: {
    showDialog: {
      get() {
        return this.show || this.dialog;
      },
      set(val) {
        if (val === false) {
          this.resetForm();
          this.$emit('close');
        }
        this.dialog = val;
      },
    },
    importButtonDisabled() {
      return this.selectedUser == null;
    },
  },
  methods: {
    setSurveyInfo(notInvitedUsers, isQuantificationSurvey = false) {
      this.isQuantificationSurvey = isQuantificationSurvey;
      this.notInvitedUsers = notInvitedUsers;
    },
    async inviteUser() {
      const surveyEndpointSlug = !this.isQuantificationSurvey
        ? 'surveys' : 'variable-surveys';

      try {
        await this.axios
          .post(`/api/${surveyEndpointSlug}/${this.$route.params.id}/invite/${this.selectedUser.id}`)
          .then((response) => {
            console.log(response.status);
            if ([201, 200].includes(response.status)) {
              this.showDialog = false;
              Swal.fire(
                'Correcto!',
                'Se ha invitado al experto correctamente',
                'success',
              )
                .then(() => {
                  this.$emit('updateList');
                });
            }
          })
          .catch((response) => {
            if (response.response.status === 422) {
              Swal.fire({
                title: 'Informacion incompleta!',
                text: `${response.response.data.message}`,
                icon: 'warning',
              })
                .then(() => {
                  this.showDialog = false;
                });
            } else {
              Swal.fire({
                title: 'Lo sentimos!',
                text: `Ha ocurrido un error. ${response.response.data.message}`,
                icon: 'error',
              })
                .then(() => {
                  this.showDialog = false;
                });
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    resetForm() {
      this.selectedUser = null;
      this.$refs.obs.reset();
    },
  },
};
</script>
