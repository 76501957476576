<template>
  <div class="fill-height">
    <v-chip
      class="mt-6 ml-6"
      color="info"
      outlined
      pill
    >
      Tabla ponderada final de encuesta
    </v-chip>
    <div
      v-if="loadingTable"
      style="width:50%; margin-left:25%"
    >
      <v-skeleton-loader
        type="table-row-divider@3"
        class="pb-10"
      ></v-skeleton-loader>
    </div>
    <div v-else-if="!loadingTable && !vpNotReady"
      class="table-container text-xs-center mb-10"
    >
      <table>
        <tr>
          <th scope="row" class="header-title">Variables</th>
          <template v-for="(trelement,index) in surveysInfo">
              <th
                v-bind:key="trelement.contact.contact_id"
                scope="col"
              >
                {{trelement.contact.name}}
              </th>
          </template>
          <th
            scope="row"
            class="footer-title"
            :style="getFooterTranslate()"
          >Vector Agregado Normalizado</th>
        </tr>
        <tr v-for="(trelement,indexa) in treeLeaves">
          <th v-bind:key="`${indexa}-varname`" scope="row" class="header">
            {{trelement.name}}
          </th>
          <template v-for="(trelement,index) in surveysInfo">
            <td class="table-container-row">
              {{roundNumber(trelement.aggregated_vp[indexa],4)}}
            </td>
          </template>
          <th
            v-bind:key="`${indexa}-varvalue`"
            scope="row"
            class="footer"
            :style="getFooterTranslate()"
          >
            {{roundNumber(finalAgregatedVp[indexa],4)}}
          </th>
        </tr>
      </table>
    </div>
    <div v-else-if="!loadingTable && vpNotReady"
      class="mb-6"
      style="text-align:center"
    >
      <v-icon
        class="pt-6"
        color="red"
        x-large
        @click="fetchInitData()"
      >
        mdi-alert
      </v-icon>
      <p class = "project-name">Calculos no encontrados</p>
      <p class = "pb-5">
        Es posible que todavia no se hayan hecho los calculos pertinentes
        a esta seccion
      </p>
    </div>
  </div>
</template>

<script>

import { mapFields } from 'vuex-map-fields';

export default {
  name: 'vagntable',
  components: {
  },
  data: () => ({
    surveysInfo: [],
    loadingTable: true,
    treeLeaves: [],
  }),
  async mounted() {
    this.fetchInitData();
  },
  methods: {
    async fetchInitData() {
      this.loadingTable = true;
      await this.findTreeLeaves(this.diagram);
      await this.fetchProjectSurveyInfo();
    },
    getFooterTranslate() {
      const pixels = (100 * (this.surveysInfo.length - 4)) * -1;
      const style = {
        transform: `translateX(${pixels - 40}px)`,
      };
      return style;
    },
    async findTreeLeaves(diagram) {
      if (!diagram) return [];

      const preorder = [];
      const stack = [];
      const leaves = [];

      stack.push(diagram);
      preorder.push(diagram);

      let par = null;

      while (stack.length > 0) {
        let flag = 0;
        if (stack[(stack.length - 1)].children.length === 0) {
          leaves.push(stack[(stack.length - 1)]);
          stack.pop();
        } else {
          par = stack[stack.length - 1];
        }

        for (let i = 0; i < par.children.length; i += 1) {
          if (preorder.indexOf(par.children[i]) === -1) {
            flag = 1;
            stack.push(par.children[i]);
            preorder.push(par.children[i]);
            break;
          }
        }

        if (flag === 0) stack.pop();
      }
      this.$set(this, 'treeLeaves', leaves);
    },
    roundNumber(number, places) {
      return parseFloat(`${Math.round(`${number}e+${places}`)}e-${places}`);
    },
    async fetchProjectSurveyInfo() {
      try {
        const response = await this.axios
          .get(`/api/projects/${this.$route.params.id}/surveys`);

        this.$set(this, 'surveysInfo', response.data.data);
        this.loadingTable = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    ...mapFields({
      diagram: 'project.diagram',
      project: 'project',
    }),
    finalAgregatedVp() {
      if (this.project && 'vp' in this.project) {
        return JSON.parse(this.project.vp);
      } return null;
    },
    vpNotReady() {
      return !('vp' in this.project) || JSON.parse(this.project.vp).length < 1;
    },
  },
};
</script>

<style type='text/css'>

  .table-container {
    margin-top: 20px;
    max-width: 400px;
    width: 400px;
    /*background-color: red;*/
    overflow-x: scroll;
    position: relative;
    margin-left: 30%;
    margin-right: 0px;
    border: 1px solid white;
  }

  .table-container th[scope=row] {
    position: fixed;
    background-color: white;
    color: rgba(0, 0, 0, 0.8);
  }

  .table-container th[scope=row].header {
    transform: translateX(-300px);
    background-color: red;
    width: 300px;
    padding: 10px;
    margin-top: 0;
    border: 1px solid #D0D0D0;
    border-radius: 0px;
    background-color: #DEDEDE;
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.8);
  }

  .table-container th[scope=row].header-title {
    transform: translateX(-300px);
    width: 300px;
    padding: 0;
    margin-top: 0;
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.8);
  }

  .table-container th[scope=row].footer {
    background-color: red;
    width: 300px;
    padding: 10px;
    margin-top: 0;
    border: 1px solid #929292;
    border-radius: 0px;
    background-color: #BED0FB;
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.8);
  }

  .table-container th[scope=row].footer-title {
    width: 300px;
    padding: 0;
    margin-top: 0;
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.8);
  }


  @media (max-width: 1170px) {
    .table-container th[scope=row].footer {
      width: 200px;
    }

    .table-container th[scope=row].footer-title {
      width: 200px;
    }

    .table-container th[scope=row].header {
      transform: translateX(-250px);
      width: 250px;
    }

    .table-container th[scope=row].header-title {
      transform: translateX(-250px);
      width: 250px;
    }
  }

  @media (max-width: 1100px) {
    .table-container th[scope=row].footer {
      width: 150px;
    }

    .table-container th[scope=row].footer-title {
      width: 150px;
    }

    .table-container th[scope=row].header {
      transform: translateX(-200px);
      width: 200px;
    }

    .table-container th[scope=row].header-title {
      transform: translateX(-200px);
      width: 200px;
    }
  }

  .table-container tbody {
    border-left: 20px solid transparent;
  }

  .table-container table {
    border-collapse: collapse;
  }

  td.table-container-row {
    padding: 10px 30px 10px 30px;
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    background-color: #EEEEEE;
    word-wrap: break-word;
  }

  td,tr {
    text-align: center;
  }
</style>
