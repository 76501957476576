<template>
  <v-row
    align="center"
    justify="center"
    class="custom-v-row"
  >
    <v-col
      cols="12"
      md="8"
    >

    <v-card
      class="elevation-6 mb-6"
      style="text-align:center"
      v-if="surveyNotFound"
    >
      <v-icon
        class="pt-6"
        color="red"
        x-large
      >
        mdi-alert
      </v-icon>
      <p class = "project-name">Encuesta no encontrada</p>
      <p class = "pb-5">
        Es posible que la encuesta haya sido removida o el proyecto ya no este disponible
      </p>
    </v-card>

    <v-card v-else class="elevation-6 mb-6">

      <div class = "instructions-title">

        <template v-if="fetchingSurveyInfo">
          <v-skeleton-loader
            style="background-color:gray"
            type="list-item-two-line"
          ></v-skeleton-loader>
        </template>

        <template v-else>
          <p class = "project-name">{{surveyInfo.data.project.name}}</p>
          <p class = "survey-creator">
          <b>{{surveyInfo.data.user.name}}</b> compartio esta encuesta contigo
          </p>
          <p v-if="surveyInfo.data.survey.finished_at" class = "survey-creator">
            <v-chip
              class="mb-2"
              color="error"
              pill>
              Esta encuesta ya ha finalizado
            </v-chip>
          </p>
        </template>

      </div>

      <div class = "container">

        <p class = "section-title mb-2">
          <b>Objetivo</b>
        </p>
        <v-divider class = "mb-2"></v-divider>

        <template v-if="fetchingSurveyInfo">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </template>

        <template v-else>
          <p class = "section-title mb-9">
            <b>
              {{surveyInfo.data.project.objective}}:
            </b>
            {{surveyInfo.data.project.description}}<br><br>
          </p>
        </template>

        <template v-if="fetchingSurveyInfo">
          <v-skeleton-loader
            type="list-item-three-line,list-item-three-line,list-item-three-line"
          ></v-skeleton-loader>
        </template>

        <template v-else>
          <div
            v-if="!surveyInfo.data.survey.finished_at"
            class="mb-10"
          >
            <p class = "section-title">
              Instrucciones
              <v-divider></v-divider>
            </p>
            <p class = "paragraph">
              Se le presentará una serie de comparaciones de variables
              por pares para cada nivel de acuerdo a los criterios establecidos anteriormente.
              En primer lugar, deberá seleccionar la variable que usted considere como más
              importante.Seguidamente, deberá determinar qué tan importante es esa variable sobre
              la otra en una escala del 1 al 9. Se recomienda realizar
              una revisión general al finalizar la encuesta de al menos dos veces para
              confirmar las respuestas y evitar inconsistencias.
              <br><br>
              En el caso de que se presenten inconsistencias en la encuesta una vez finalizada lo
              determinaremos y te lo haremos saber, de forma que puedas
              revisar nuevamente tu encuesta para que puedas asegurarte de cumplir con
              la consistencia y puedas enviar la encuesta.
            </p>
          </div>
        </template>

        <p class = "section-title mt-6">Descripcion de variables por niveles</p>
        <v-divider class = "mb-2"></v-divider>

        <template v-if="fetchingSurveyInfo">
          <v-skeleton-loader
            type="list-item-three-line,list-item-three-line,list-item-three-line"
          ></v-skeleton-loader>
        </template>

        <div class = "container" v-else>
          <InstructionsVarDescription
            :survey-tree="surveyTree"
            :project-objective="projectObjective"
          ></InstructionsVarDescription>
        </div>

        <br><br>

        <div class = "start-button mb-2">
          <v-btn
            color="primary"
            dark
            @click="startSurvey"
          >
          <template v-if="!fetchingSurveyInfo && surveyInfo.data.survey.finished_at">
            Ver resultados
          </template>
          <template v-else>Comenzar encuesta</template>
        </v-btn>
        </div>

      </div>

    </v-card>

    </v-col>
  </v-row>
</template>

<script>

import { mapFields } from 'vuex-map-fields';
import InstructionsVarDescription from '../InstructionsVarDescription.vue';

export default {
  name: 'Instructions',
  components: {
    InstructionsVarDescription,
  },
  data: () => ({
    surveyInfo: null,
    fetchingSurveyInfo: true,
    surveyNotFound: false,
  }),
  async mounted() {
    this.surveyStarted = false;
    await this.fetchSurveyInfo();
  },
  computed: {
    criteriaData() {
      return this.surveyInfo ? this.surveyInfo.data.criteria : null;
    },
    surveyTree() {
      return this.surveyInfo ? this.surveyInfo.data.surveyTree : null;
    },
    projectObjective() {
      return this.surveyInfo ? this.surveyInfo.data.project.objective : null;
    },
    ...mapFields({
      surveyStarted: 'surveyStarted',
      surveyIsFinished: 'surveyIsFinished',
    }),
  },
  methods: {
    async fetchSurveyInfo() {
      this.fetchingSurveyInfo = true;

      try {
        this.axios
          .get(`/api/surveys/${this.$route.params.id}`)
          .then((response) => {
            if ([200, 204, 201].includes(response.status)) {
              this.$set(this, 'surveyInfo', response.data);
              this.findOutIfSurveyIsFinished();
              this.surveyIsStarted();
            }
          })
          .catch((response) => {
            if (response.response.status === 404) {
              this.$set(this, 'surveyNotFound', true);
              this.surveyStarted = false;
            }
          });
      } catch (error) {
        console.error(error);
      }
      this.fetchingSurveyInfo = false;
    },
    surveyIsStarted() {
      this.$set(this, 'surveyStarted', this.surveyInfo.data.survey.started_at !== null);
    },
    findOutIfSurveyIsFinished() {
      this.$set(this, 'surveyIsFinished', this.surveyInfo.data.survey.finished_at !== null);
    },
    startSurvey() {
      this.surveyStarted = true;
      this.$router.push({ name: 'RunSurvey', params: { id: this.$route.params.id } });
    },
  },
  errorCaptured(err, vm, info) {
    this.error = `${err}\n\nfound in ${info} of component`;
    return false;
  },
};
</script>

<style>

.custom-v-row {
  height: calc(100vh - 98px);
  overflow-y: scroll;
  margin: 0;
}

.start-button {
  margin-top: 3%;
  text-align: center;
}

div.instructions-title {
  margin-top: 1em;
  text-align: center;
  background-color: #E8E8E8;
  width: 100%;
}

p.project-name {
  padding-top: 10px;
  color: #5C5C5C;
  font-weight: bold;
  font-size:1.1em;
}

p.survey-creator {
  padding-bottom: 10px;
}

p.section-title {
  font-size:1.09em;
  color: #5C5C5C;
}

p.paragraph {
  text-align: justify;
  margin: 2% 5% 2% 5%;
}

</style>
