<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-main>
      <v-row
        align="center"
        justify="center"
        style="height: 100%;"
      >
        <v-col
          cols="12"
          sm="8"
          md="6"
        >
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>
                Verifique su dirección de correo electrónico
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="body-1">
              Antes de continuar, consulte su correo electrónico
              para ver un enlace de verificación.<br />
              Si no ha recibido el correo electrónico,
              <a
                  @click.prevent="resendVerification"
                  href="javascript:void(0);"
              >
                  haga clic aquí para solicitar otro.
              </a>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ForgotPassword',
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapFields([
      'user',
    ]),
  },
  mounted() {
    this.axios.get('/api/auth/is-verified')
      .then(({ data: { meta } }) => {
        if (meta.verified) {
          this.user.email_verified_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
          this.$router.push('/').catch(() => {});
        }
      })
      .catch((error) => {
        console.error(error);

        Swal.fire({
          title: '¡Error!',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Aceptar',
        }).then(() => {
          this.$router.push('/login').catch(() => {});
        });
      });
  },
  methods: {
    resendVerification() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.axios.post('/api/auth/resend-verification-email')
        .then(() => {
          Swal.fire({
            title: '¡Listo!',
            text: 'Se ha enviado un enlace de verificación a su correo electrónico.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          });
        })
        .catch((error) => {
          Swal.fire({
            title: '¡Error!',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
