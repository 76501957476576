<template>
  <div class="fill-height stepper-bar">
    <v-tabs
      :loading="true"
    >
      <v-tab
        v-for="(step, i) in steps"
        :key="i"
        :to="{ path: step.path, params: { id: $route.params.id } }"
        :disabled="step.locked"
      >
        {{ step.name }}
      </v-tab>
    </v-tabs>
    <router-view />
    <v-snackbar
      v-model="snackbar"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Closesdads
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'SurveyViewer',
  data: () => ({
    loading: true,
    snackbar: false,
    message: '',
  }),
  computed: {
    ...mapFields({
      surveyStarted: 'surveyStarted',
      survey: 'survey',
      temporaryDrawer: 'temporaryDrawer',
      appBarSize: 'appBarSize',
    }),
    steps() {
      return [
        { name: 'Instrucciones', path: 'instrucciones', locked: false },
        { name: 'Encuesta', path: 'ejecutar', locked: !this.surveyStarted },
        // { name: 'Diagrama', path: 'diagrama', locked: true },
      ];
    },
  },
  async mounted() {
    this.temporaryDrawer = true;
    this.appBarSize = 50;
    await this.loadProjectData();

    const params = { id: this.$route.params.id };

    if (this.$route.name === 'SurveyViewer') {
      // If the survey hasn't started yet
      this.$router.push({ name: 'Instructions', params });
    }
  },
  methods: {
    loadProjectData() {
    },
    goBack() {
      this.$router.push('/');
    },
  },
  beforeDestroy() {
    this.temporaryDrawer = false;
    this.appBarSize = null;
  },
};
</script>

<style>
</style>
