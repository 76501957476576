<template>
  <div class="fill-height">

    <v-toolbar>
      <template v-if="loadingTable">
        <v-skeleton-loader
          type="actions"
          height="40"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="actions"
          height="40"
        ></v-skeleton-loader>
      </template>
      <template v-else-if="!loadingTable">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="openAddingModal"
              :disabled="!!variableSurveysMetadata.assessment.comparables_locked"
            >
              <v-icon>mdi-home-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar {{ alternativeLabel }}</span>
        </v-tooltip>

        <v-divider vertical></v-divider>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="showLockComparablesModal = true"
              :disabled="!!variableSurveysMetadata.assessment.comparables_locked"
            >
              <v-icon>mdi-lock</v-icon>
            </v-btn>
          </template>
          <span>Bloquear {{ alternativesLabel }}</span>
        </v-tooltip>

        <v-divider vertical></v-divider>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="showUnlockComparablesModal = true"
              :disabled="!variableSurveysMetadata.assessment.comparables_locked"
            >
              <v-icon>mdi-lock-open</v-icon>
            </v-btn>
          </template>
          <span>Desbloquear {{ alternativesLabel }}</span>
        </v-tooltip>

        <v-divider vertical></v-divider>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="variableSurveysMetadata"
              icon
              v-bind="attrs"
              v-on="on"
              @click="showQuantificationSurveysModal = true"
              :disabled="!variableSurveysMetadata.hasQualitativeVariables ||
              !variableSurveysMetadata.assessment.comparables_locked"
            >
              <v-icon>mdi-application-variable</v-icon>
            </v-btn>
          </template>
          <span>Administrar variables cualitativas</span>
        </v-tooltip>

        <v-divider vertical></v-divider>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="showCalculateModal = true"
              :disabled="!!disableCalculateButton"
            >
              <v-icon>mdi-arrow-right-bold-box</v-icon>
            </v-btn>
          </template>
          <span>Calcular</span>
        </v-tooltip>
      </template>
    </v-toolbar>

    <v-row no-gutters style="height: calc(100% - 74px)">

        <v-col md="12">
          <div id="tree-parent">

          <template>
            <v-container
              fluid
              class="fill-height"
            >
              <v-content>
                <v-row
                align="center"
                justify="center"
                >
                  <v-col
                  cols="12"
                  md="10"
                  style="max-width:1500px;"
                  >

                  <v-tabs
                    v-model="tab"
                    v-if="!loadingTable"
                  >
                    <v-tab>
                      Datos {{ alternativesLabel }}
                    </v-tab>
                    <v-tab
                      :disabled="!variableSurveysMetadata.assessmentIsFinished"
                    >
                      Datos Transformados
                    </v-tab>
                    <v-tab
                      :disabled="!variableSurveysMetadata.assessmentIsFinished"
                    >
                      Datos normalizados
                    </v-tab>
                    <v-tab
                      :disabled="!variableSurveysMetadata.assessmentIsFinished"
                    >
                      Ponderacion final
                    </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <template
                        v-if="loadingTable"
                      >
                        <v-skeleton-loader
                          type="table-row-divider@3"
                          class="pl-10 pr-10 pb-10 pt-10"
                        ></v-skeleton-loader>
                      </template>
                      <v-simple-table
                        class="mt-5 mr-5 ml-5 mb-5"
                        dense
                        fixed-header
                        height="300px"
                        v-else-if="!loadingTable &&
                        comparables.length > 0"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                :colspan="projectConfig.fixed_price_field ? 3 : 2"
                                class="text-center vagn-title"
                                style="background-color: #BED0FB;"
                              >
                                Vector agregado normalizado
                              </th>
                              <template v-for="e in finalAgregatedVp">
                                <th
                                  class="text-center vagn-content-row"
                                  style="background-color: #EEEEEE;"
                                >
                                  {{e}}
                                </th>
                              </template>
                              <th
                                >
                                </th>
                            </tr>
                            <tr>
                              <th class="text-center">
                                {{ alternativeLabel | capitalize }}
                              </th>
                              <th class="text-center">
                                Descripcion
                              </th>
                              <th class="text-center" v-if="projectConfig.fixed_price_field">
                                Precio
                              </th>
                              <template v-for="leaf in treeLeaves">
                                <th class="text-center">
                                  {{leaf.name}}
                                </th>
                              </template>
                              <th>
                                Acciones
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(comparable,index) in comparables">
                              <td>
                                <v-chip
                                  v-if="!comparable.is_active_x"
                                  x-small
                                  color="default"
                                  pill>
                                  {{ alternativePrefix }}{{index + 1}}
                                </v-chip>
                                <v-chip
                                  v-else
                                  x-small
                                  color="primary"
                                  pill>
                                  {{ alternativePrefix }}X
                                </v-chip>
                              </td>
                              <td>
                                <v-text-field
                                  class="pt-0 pb-3 pr-0 pl-0"
                                  v-model="comparable.description"
                                  @blur="handleBlur(comparable)"
                                  autocomplete="off"
                                  hide-details
                                  single-line
                                  :disabled="!!variableSurveysMetadata.assessment.comparables_locked"
                                />
                              </td>
                              <template v-if="projectConfig.fixed_price_field">
                                <td v-if="!comparable.is_active_x">
                                  <v-text-field
                                    class="pt-0 pb-3 pr-0 pl-0"
                                    v-model="comparable.price"
                                    @blur="handleBlur(comparable)"
                                    autocomplete="off"
                                    hide-details
                                    single-line
                                    type="number"
                                    :disabled="comparable.is_active_x ||
                                    !!variableSurveysMetadata.assessment.comparables_locked"
                                    :placeholder="pricePlaceHolder(comparable)"
                                    min="0"
                                  />
                                </td>
                                <td v-else>
                                  <v-text-field
                                    class="pt-0 pb-3 pr-0 pl-0"
                                    autocomplete="off"
                                    hide-details
                                    single-line
                                    type="number"
                                    :disabled="true"
                                    placeholder="no aplica"
                                  />
                                </td>
                              </template>
                              <template v-for="(leaf,index) in treeLeaves">
                                <td
                                  v-if="getVariableInput(leaf, comparable) === 'textInput'"
                                  class="text-center"
                                >
                                  <v-text-field
                                    class="pt-0 pb-3 pr-0 pl-0 mt-2"
                                    v-model="comparable.variables[getVarIndex(comparable,leaf)].value"
                                    @blur="handleBlur(comparable)"
                                    autocomplete="off"
                                    hide-details
                                    single-line
                                    dense
                                    type="number"
                                    :disabled="!!variableSurveysMetadata.assessment.comparables_locked"
                                    min="0"
                                  />
                                </td>
                                <td
                                  v-else-if="getVariableInput(leaf, comparable) === 'selectInput'"
                                  class="text-center"
                                >
                                  <v-select
                                    class="pt-0 pr-0 pl-0 mt-3"
                                    v-model="comparable.variables[getVarIndex(comparable,leaf)].category"
                                    :items="getAlternativesFromRestrictedVar(leaf, comparable)"
                                    label="Categoria"
                                    @blur="handleBlur(comparable)"
                                    :disabled="!!variableSurveysMetadata.assessment.comparables_locked"
                                    persistent-hint
                                    single-line
                                    autocomplete="off"
                                    single-line
                                    dense
                                    max-width="10px"
                                  ></v-select>
                                </td>
                                <td v-else class="cualitative-input-cell">
                                  Cualitativa Libre
                                </td>
                              </template>
                              <td>
                                <v-icon
                                  :disabled="!!variableSurveysMetadata.assessment.comparables_locked"
                                  small
                                  color="#F99191"
                                  class="mr-2"
                                  @click="deleteComparable(comparable)"
                                >
                                  delete
                                </v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <div v-else
                        class="mb-6"
                        style="text-align:center"
                      >
                        <p class = "mt-5 no-comparables-found">
                          No se han agregado {{ alternativesLabel }}
                        </p>
                      </div>
                    </v-tab-item>
                    <v-tab-item>
                      <v-simple-table
                        class="mt-5 mr-5 ml-5 mb-5"
                        dense
                        fixed-header
                        style="max-height: 500px; overflow-y: auto;"
                        v-if="variableSurveysMetadata &&
                        variableSurveysMetadata.assessmentIsFinished"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                :colspan="projectConfig.fixed_price_field ? 3 : 2"
                                class="text-center vagn-title"
                                style="background-color: #BED0FB;"
                              >
                                Vector agregado normalizado
                              </th>
                              <template v-for="e in finalAgregatedVp">
                                <th
                                  class="text-center vagn-content-row"
                                  style="background-color: #EEEEEE;"
                                >
                                  {{e}}
                                </th>
                              </template>
                            </tr>
                            <tr>
                              <th class="text-center">
                                {{ alternativeLabel | capitalize }}
                              </th>
                              <th class="text-center">
                                Descripcion
                              </th>
                              <th class="text-center" v-if="projectConfig.fixed_price_field">
                                Precio
                              </th>
                              <template v-for="leaf in treeLeaves">
                                <th class="text-center">
                                  {{leaf.name}}
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(comparable,index) in comparables">
                              <td>
                                <v-chip
                                  v-if="!comparable.is_active_x"
                                  x-small
                                  color="default"
                                  pill>
                                  {{ alternativePrefix }}{{index + 1}}
                                </v-chip>
                                <v-chip
                                  v-else
                                  x-small
                                  color="primary"
                                  pill>
                                  {{ alternativePrefix }}X
                                </v-chip>
                              </td>
                              <td>{{comparable.description}}</td>

                              <template v-if="projectConfig.fixed_price_field">
                                <td v-if="!comparable.is_active_x">{{roundNumber(comparable.price,4)}}</td>
                                <td v-else>no aplica</td>
                              </template>
                              <template v-for="compvar in comparable.variables">
                                <td>
                                  {{roundNumber(compvar.transformedValue,4)}}
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-tab-item>
                    <v-tab-item>
                      <v-simple-table
                        class="mt-5 mr-5 ml-5 mb-5"
                        dense
                        fixed-header
                        style="max-height: 5"
                        v-if="variableSurveysMetadata &&
                        variableSurveysMetadata.assessmentIsFinished"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                :colspan="projectConfig.fixed_price_field ? 3 : 2"
                                class="text-center vagn-title"
                                style="background-color: #BED0FB;"
                              >
                                Vector agregado normalizado
                              </th>
                              <template v-for="e in finalAgregatedVp">
                                <th
                                  class="text-center vagn-content-row"
                                  style="background-color: #EEEEEE;"
                                >
                                  {{e}}
                                </th>
                              </template>
                            </tr>
                            <tr>
                              <th class="text-center">
                                {{ alternativeLabel | capitalize }}
                              </th>
                              <th class="text-center">
                                Descripcion
                              </th>
                              <th class="text-center" v-if="projectConfig.fixed_price_field">
                                Precio
                              </th>
                              <template v-for="leaf in treeLeaves">
                                <th class="text-center">
                                  {{leaf.name}}
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(comparable,index) in comparables">
                              <td>
                                <v-chip
                                  v-if="!comparable.is_active_x"
                                  x-small
                                  color="default"
                                  pill>
                                  {{ alternativePrefix }}{{index + 1}}
                                </v-chip>
                                <v-chip
                                  v-else
                                  x-small
                                  color="primary"
                                  pill>
                                  {{ alternativePrefix }}X
                                </v-chip>
                              </td>
                              <td>{{comparable.description}}</td>

                              <template v-if="projectConfig.fixed_price_field">
                                <td v-if="!comparable.is_active_x">{{roundNumber(comparable.price, 4)}}</td>
                                <td v-else>no aplica</td>
                              </template>
                              <template v-for="compvar in comparable.variables">
                                <td>
                                  {{roundNumber(compvar.normalizedValue,4)}}
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-tab-item>
                    <v-tab-item>
                      <v-simple-table
                        class="mt-5 mr-5 ml-5 mb-5"
                        dense
                        fixed-header
                        style="max-height: 5"
                        v-if="variableSurveysMetadata &&
                        variableSurveysMetadata.assessmentIsFinished"
                      >
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center">
                                {{ alternativeLabel | capitalize }}
                              </th>
                              <th class="text-center">
                                Descripcion
                              </th>
                              <th class="text-center" v-if="projectConfig.fixed_price_field">
                                Precio observado
                              </th>
                              <th class="text-center">
                                Ponderacion final de {{ alternativeLabel }}
                              </th>
                              <th class="text-center" v-if="project.type === 'ahp_mercado'">
                                Precio calculado
                              </th>
                              <th class="text-center" v-if="project.type === 'ahp_mercado'">
                                Error porcentual
                              </th>
                              <th class="text-center" v-if="project.type === 'ahp_mercado'">
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(comparable,index) in comparables">
                              <td>
                                <v-chip
                                  v-if="!comparable.is_active_x"
                                  x-small
                                  color="default"
                                  pill>
                                  {{ alternativePrefix }}{{index + 1}}
                                </v-chip>
                                <v-chip
                                  v-else
                                  x-small
                                  color="primary"
                                  pill>
                                  {{ alternativePrefix }}X
                                </v-chip>
                              </td>
                              <td>{{comparable.description}}</td>

                              <template v-if="projectConfig.fixed_price_field">
                                <td v-if="!comparable.is_active_x">{{roundNumber(comparable.price,4)}}</td>
                                <td v-else>{{roundNumber(finalPriceActiveX,4)}}</td>
                              </template>
                              <td>{{roundNumber(finalWeightArray[index],4)}}</td>

                              <td v-if="!comparable.is_active_x && project.type === 'ahp_mercado'">
                                {{ calculatedPrice(finalWeightArray[index]) | fillDecimalsZeroes(2) }}
                              </td>
                              <td
                                v-if="!comparable.is_active_x && project.type === 'ahp_mercado'"
                                :class="{
                                  'background-positive': isPercentErrorSignPositive(finalWeightArray[index], comparable.price),
                                  'background-negative': !isPercentErrorSignPositive(finalWeightArray[index], comparable.price)
                                }"
                              >
                                {{ percentError(finalWeightArray[index], comparable.price) | fillDecimalsZeroes(2) }}%
                              </td>
                              <td
                                v-if="!comparable.is_active_x && project.type === 'ahp_mercado'"
                                :class="{
                                  'background-positive': isPercentErrorSignPositive(finalWeightArray[index], comparable.price),
                                  'background-negative': !isPercentErrorSignPositive(finalWeightArray[index], comparable.price)
                                }"
                              >
                                {{ isPercentErrorSignPositive(finalWeightArray[index], comparable.price) ? '+' : '-' }}
                              </td>
                            </tr>
                          </tbody>
                          <table
                            class="vagn-tiny-table"
                            v-if="projectConfig.fixed_price_field"
                          >
                            <tr>
                              <td class="vagn-title">Ratio final</td>
                              <td class="vagn-content-row">
                                {{roundNumber(variableSurveysMetadata.assessment.weighted_ratio,4)}}
                              </td>
                            </tr>
                            <tr>
                              <td class="vagn-title">Valor activo final</td>
                              <td class="vagn-content-row">
                                {{roundNumber(finalPriceActiveX,4)}}
                              </td>
                            </tr>
                            <tr v-if="project.type === 'ahp_mercado'">
                              <td class="vagn-title">MAPE</td>
                              <td class="vagn-content-row">
                                {{ mape }}%
                              </td>
                            </tr>
                          </table>
                        </template>
                      </v-simple-table>
                    </v-tab-item>
                  </v-tabs-items>

                  </v-col>
                </v-row>
              </v-content>
            </v-container>
          </template>

          </div>
        </v-col>
    </v-row>


    <v-dialog
      v-model="showComparablesAddingModal"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline text-center">
          Agregando un comparable
        </v-card-title>

        <v-card-text class="text-justify">
          Usted esta creando un comparable, pero no ha creado la propiedad
          a tasar. ¿Desea crearla ahora?

          <v-btn
            class="mt-6 mr-1"
            width="49%"
            color="normal"
            @click="addComparable()"
          >
            Comparable
          </v-btn>

          <v-btn
            class="mt-6"
            width="49%"
            color="primary"
            @click="addComparable(true)"
          >
            Propiedad
          </v-btn>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="showComparablesAddingModal = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showCalculateModal"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline text-center">
          Realizar Calculos finales
        </v-card-title>

        <v-card-text class="text-justify">
          Usted esta a un paso de realizar los calculos finales.

          {{ finalCalculationsMessage }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="showCalculateModal = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="processData()"
            :disabled="!!processingData"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showLockComparablesModal"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline text-center">
          Bloquear {{ alternativesLabel }}
        </v-card-title>

        <v-card-text class="text-justify">
          Usted esta a un paso de bloquear {{ alternativesLabelWithPronoun }}.
        </v-card-text>

        <v-card-text class="text-justify">
          Si ya se ha ingresado toda la informacion pertinente de {{ alternativesLabelWithPronoun }}, bloquearlos
          permitira desbloquear la posibilidad de realizar los calculos finales, en caso de que el proyecto
          no tenga variables cualitativas.
        </v-card-text>

        <v-card-text>
          En caso contrario, permitira administrar las encuestas relacionadas a la cuantificacion de
          variables cualitativas, para luego realizar los calculos finales.
        </v-card-text>

        <v-card-text>
          <strong>
            Este paso se puede deshacer
          </strong>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="showLockComparablesModal = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="lockComparables()"
            :disabled="lockingComparables"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showUnlockComparablesModal"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline text-center">
          Desbloquear {{ alternativesLabel }}
        </v-card-title>

        <v-card-text class="text-justify">
          Usted esta a un paso de desbloquear {{ alternativesLabelWithPronoun }}.
        </v-card-text>

        <v-card-text class="text-justify">
          Esto devolvera todo a un estado previo que permitira ingresar mas {{ alternativesLabel }} o cambiar
          su información.
        </v-card-text>

        <v-card-text>
          <strong>
            Este paso se puede deshacer
          </strong>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="showUnlockComparablesModal = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            :disabled="unlockingComparables"
            @click="unlockComparables()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <quantification-surveys-modal
      v-if="variableSurveysMetadata &&
      !(!variableSurveysMetadata.hasQualitativeVariables ||
      !variableSurveysMetadata.assessment.comparables_locked)"
      :show="showQuantificationSurveysModal"
      @close="showQuantificationSurveysModal = false"
      @updateAssessmentMetadata="fetchVariableSurveysMetadata"
      ref="quantificationSurveysModal"
    />

  </div>
</template>

<script>

import { mapFields } from 'vuex-map-fields';
import QuantificationSurveysModal from '@/components/QuantificationSurveysModal.vue';
import Swal from 'sweetalert2';

export default {
  name: 'Process',
  components: {
    QuantificationSurveysModal,
  },
  data: () => ({
    tab: 0,
    surveysInfo: [],
    matrix: null,
    processingData: false,
    loadingTable: true,
    treeLeaves: [],
    showQuantificationSurveysModal: false,
    showComparablesAddingModal: false,
    showLockComparablesModal: false,
    showUnlockComparablesModal: false,
    lockingComparables: false,
    unlockingComparables: false,
    showCalculateModal: false,
    comparables: [],
    variableSurveysMetadata: null,
    comparablesInputFinished: false,
  }),
  async mounted() {
    this.loadingTable = true;

    await this.findTreeLeaves(this.diagram);

    await this.fetchProjectSurveyInfo();

    await this.fetchComparables();

    await this.fetchVariableSurveysMetadata();

    this.loadingTable = false;
  },
  methods: {
    processData() {
      this.processingData = true;
      try {
        this.axios
          .post(`api/projects/${this.$route.params.id}/process-data`)
          .then((response) => {
            this.processingData = false;
            if ([200].includes(response.status)) {
              this.showCalculateModal = false;
              Swal.fire(
                'Correcto!',
                'Se han realizado los calculos correctamente',
                'success',
              )
                .then(() => {
                  this.fetchVariableSurveysMetadata();
                });
            }
          })
          .catch((response) => {
            this.processingData = false;
            this.showCalculateModal = false;
            Swal.fire(
              'Error de calculos finales!',
              response.response.data.message,
              'warning',
            );
          });
      } catch (error) {
        this.processingData = false;
        console.error(error);
      }
    },
    async lockComparables() {
      try {
        this.lockingComparables = true;
        this.axios
          .post(`/api/projects/${this.$route.params.id}/assessment/lock-comparables`)
          .then((response) => {
            console.log(response.status);
            this.lockingComparables = false;
            if ([200].includes(response.status)) {
              this.showLockComparablesModal = false;
              Swal.fire(
                'Correcto!',
                this.alternativesLabelWithPronoun + ' se han bloqueado correctamente',
                'success',
              )
                .then(() => {
                  this.fetchVariableSurveysMetadata();
                });
            }
          })
          .catch((response) => {
            this.lockingComparables = false;
            this.showLockComparablesModal = false;
            if (response.response.status === 422) {
              Swal.fire(
                'Error',
                response.response.data.message,
                'warning',
              );
            }
          });
      } catch (error) {
        this.lockingComparables = false;
        console.error(error);
      }
    },
    async unlockComparables() {
      this.unlockingComparables = true;
      try {
        this.axios
          .post(`/api/projects/${this.$route.params.id}/assessment/unlock-comparables`)
          .then((response) => {
            this.unlockingComparables = false;
            console.log(response.status);
            if ([200].includes(response.status)) {
              this.showUnlockComparablesModal = false;
              Swal.fire(
                'Correcto!',
                this.alternativesLabelWithPronoun + ' se han desbloqueado correctamente',
                'success',
              )
                .then(() => {
                  this.fetchVariableSurveysMetadata();
                  this.tab = 0;
                });
            }
          })
          .catch((response) => {
            this.unlockingComparables = false;
            this.showUnlockComparablesModal = false;
            if (response.response.status === 422) {
              Swal.fire(
                'Error',
                response.response.data.message,
                'warning',
              );
            }
          });
      } catch (error) {
        this.unlockingComparables = false;
        console.error(error);
      }
    },
    async fetchVariableSurveysMetadata() {
      try {
        const response = await this.axios
          .get(`/api/projects/${this.$route.params.id}/assessment`);

        this.$set(this, 'variableSurveysMetadata', response.data.data);
        this.$set(this, 'comparablesInputFinished', this.variableSurveysMetadata.comparablesInputFinished);

        if (this.variableSurveysMetadata.assessmentIsFinished) {
          this.fetchComparables();
        }
      } catch (error) {
        console.error(error);
      }
    },
    pricePlaceHolder(comparable) {
      return comparable.is_active_x ? 'no aplica' : '';
    },
    getAlternativesFromRestrictedVar(leaf, comparable) {
      return comparable.variables.find(lvar => lvar.id === leaf.id).categories;
    },
    getVariableInput(leaf, comparable) {
      const variableFound = comparable.variables.find(lvar => lvar.id === leaf.id);
      if (variableFound.type === 'cuantitativa') {
        return 'textInput';
      } else if (variableFound.type === 'cualitativa' && variableFound.property === 'restringida') {
        return 'selectInput';
      } return 'noInput';
    },
    getVarIndex(comparable, lvar) {
      return comparable.variables.map(v => v.id).indexOf(lvar.id);
    },
    handleBlur(comparable) {
      this.updateComparable(comparable);
    },
    getBodyTranslation() {
      const pixels = (this.tab === 0) ? '74px' : '0px';

      return `height: calc(100% - ${pixels})`;
    },
    openAddingModal() {
      if (!this.propertyAlreadyCreated && this.projectConfig.has_active_x) {
        this.showComparablesAddingModal = true;
      } else {
        this.addComparable();
      }
    },
    async updateComparable(comparableBody) {
      const response = await this.axios.put(
        `/api/projects/${this.$route.params.id}/comparable/${comparableBody.id}`,
        comparableBody
      );
      this.$set(this, 'comparablesInputFinished', response.data.data.comparablesInputFinished);
    },
    async addComparable(isProperty = false) {
      const comparable = {
        description: '',
        price: '',
        is_active_x: isProperty,
      };

      await this.axios.post(`/api/projects/${this.$route.params.id}/comparable`, comparable);

      await this.fetchComparables();

      this.showComparablesAddingModal = false;

      await this.fetchVariableSurveysMetadata();
    },
    async findTreeLeaves(diagram) {
      if (!diagram) return [];

      const preorder = [];
      const stack = [];
      const leaves = [];

      stack.push(diagram);
      preorder.push(diagram);

      let par = null;

      while (stack.length > 0) {
        let flag = 0;
        if (stack[(stack.length - 1)].children.length === 0) {
          leaves.push(stack[(stack.length - 1)]);
          stack.pop();
        } else {
          par = stack[stack.length - 1];
        }

        for (let i = 0; i < par.children.length; i += 1) {
          if (preorder.indexOf(par.children[i]) === -1) {
            flag = 1;
            stack.push(par.children[i]);
            preorder.push(par.children[i]);
            break;
          }
        }

        if (flag === 0) stack.pop();
      }
      this.$set(this, 'treeLeaves', leaves);
    },
    roundNumber(number, places) {
      return parseFloat(`${Math.round(`${number}e+${places}`)}e-${places}`);
    },
    calculatedPrice(comparableWeight) {
      const weightedRatio = this.roundNumber(this.variableSurveysMetadata.assessment.weighted_ratio,4)
      return this.roundNumber(this.roundNumber(comparableWeight, 4) * weightedRatio, 2);
    },
    percentError(finalWeight, observedPrice) {
      const calculatedPrice = this.calculatedPrice(finalWeight);
      return this.roundNumber(Math.abs((calculatedPrice / observedPrice) - 1) * 100, 2);
    },
    isPercentErrorSignPositive(finalWeight, observedPrice) {
      const calculatedPrice = this.calculatedPrice(finalWeight);
      return this.roundNumber((calculatedPrice / observedPrice) - 1, 2) >= 0;
    },
    arrayMove(arr, fromIndex, toIndex) {
      if (fromIndex == -1) {
        return arr;
      }
      let element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
      return arr;
    },
    async fetchProjectSurveyInfo() {
      try {
        const response = await this.axios
          .get(`/api/projects/${this.$route.params.id}/surveys`);

        this.$set(this, 'surveysInfo', response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchComparables() {
      try {
        const response = await this.axios
          .get(`/api/projects/${this.$route.params.id}/comparable`);

        const compArray = response.status === 204 ? [] : this.arrayMove(
          response.data.slice(),
          response.data.indexOf(response.data.find(comp => comp.is_active_x == true)),
          response.data.length - 1
        );

        this.$set(this, 'comparables', compArray);
      } catch (error) {
        console.error(error);
      }
    },
    async deleteComparable(comparable) {
      try {
        const response = await this.axios
          .delete(`/api/projects/${this.$route.params.id}/comparable/${comparable.id}`);

        this.$set(this, 'comparables', this.comparables.filter(c => c.id !== comparable.id));

        await this.fetchVariableSurveysMetadata();
      } catch (error) {
        console.error(error);
      }
    },
    getPriceOfComparable(comparable) {
      return (comparable.price && !comparable.is_active_x)
        ? comparable.price : 'no aplica';
    },
  },
  computed: {
    ...mapFields({
      diagram: 'project.diagram',
      project: 'project',
      projectConfig: 'project.config',
    }),
    mape() {
      if (this.project.type !== 'ahp_mercado') {
        return null;
      }

      let val = 0;
      for (let i = 0; i < this.comparables.length; i += 1) {
        if (this.comparables[i].is_active_x) {
          continue;
        }
        val += this.percentError(this.finalWeightArray[i], this.comparables[i].price);
      }

      return this.roundNumber(val / (this.comparables.length - 1), 2);
    },
    disableCalculateButton() {
      const metadata = this.variableSurveysMetadata;
      return metadata.assessmentIsFinished
        || !((metadata.hasQualitativeVariables && metadata.assessment.surveys_finished)
        || !metadata.hasQualitativeVariables && metadata.assessment.comparables_locked);
    },
    alternativePrefix() {
      if (this.project.type === 'ahp_mercado') {
        return 'C';
      }

      if (this.project.type === 'ahp_clasico') {
        return 'A';
      }
    },
    alternativeLabel() {
      if (this.project.type === 'ahp_mercado') {
        return 'comparable';
      }

      if (this.project.type === 'ahp_clasico') {
        return 'alternativa';
      }
    },
    alternativesLabel() {
      if (this.project.type === 'ahp_mercado') {
        return 'comparables';
      }

      if (this.project.type === 'ahp_clasico') {
        return 'alternativas';
      }
    },
    alternativesLabelWithPronoun() {
      if (this.project.type === 'ahp_mercado') {
        return 'los comparables';
      }

      if (this.project.type === 'ahp_clasico') {
        return 'las alternativas';
      }
    },
    finalCalculationsMessage() {
      if (this.project.type === 'ahp_mercado') {
        return 'Dichos calculos permitiran mostrar las variables de los comparables transformadas y normalizadas, asi como tambien la ponderacion final con el valor estimado del activo x.';
      }

      if (this.project.type === 'ahp_clasico') {
        return 'Dichos calculos permitiran mostrar las variables de las alternativas transformadas y normalizadas, asi como tambien la ponderacion final.';
      }
    },
    finalPriceActiveX(){
      if (this.comparables) {
        return this.comparables
          .find(comparable => comparable.is_active_x === true).price;
      } return null;
    },
    finalWeightArray() {
      if (this.variableSurveysMetadata) {
        return JSON.parse(this.variableSurveysMetadata.assessment.final_weight);
      } return [];
    },
    propertyAlreadyCreated() {
      return this.comparables.some(comparable => comparable.is_active_x === true);
    },
    finalAgregatedVp() {
      if (this.project && 'vp' in this.project) {
        return JSON.parse(this.project.vp).map(n => this.roundNumber(n, 4));
      } return null;
    },
  },
  filters: {
    capitalize: str => `${str[0].toUpperCase()}${str.slice(1)}`,
    fillDecimalsZeroes: (num, decimals) => {
      const numStr = num.toString();
      const numDecimals = numStr.split('.')[1] ? numStr.split('.')[1].length : 0;
      if (numDecimals < decimals) {
        return `${numStr}${'0'.repeat(decimals - numDecimals)}`;
      }
      return numStr;
    },
  },
  watch: {
    showQuantificationSurveysModal(newVal) {
      if (!newVal) {
        this.fetchVariableSurveysMetadata();
      }
    },
  },
};
</script>

<style type='text/css'>

.background-positive {
  background-color: #C8E6C9;
}

.background-negative {
  background-color: #FF8A80;
}

  .cualitative-input-cell {
    background-image: repeating-linear-gradient(
        45deg, #EEEEEE, #BED0FB 1px, transparent 2px, transparent 5px
    );
  }

  .no-comparables-found {
    color: rgba(0, 0, 0, 0.38);
  }

  .vagn-tiny-table {
    margin-top: 10px;
    width: 90%;
    margin-left: 5%;
  }

  th.vagn-title, td.vagn-title {
    border-radius: 3px;
    background-color: #BED0FB;
    border: 1px solid #929292;
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.8);
  }

  th.vagn-content-row, td.vagn-content-row {
    border-radius: 3px;
    background-color: #EEEEEE;
    border: 1px solid #D0D0D0;
    word-wrap: break-word;
  }

</style>
