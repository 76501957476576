<template>
  <v-dialog
    v-model="showDialog"
    max-width="1000px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ cardTitle }}</span>
      </v-card-title>
      <v-card-text>
        <ValidationObserver
          ref="obs"
        >
          <v-form ref="projectForm">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="name"
              rules="required"
            >
              <v-text-field
                v-model="name"
                label="Nombre"
                :error-messages="errors"
                :success="valid"
              />
            </ValidationProvider>
            <v-textarea
              v-model="description"
              name="Description"
              label="Descripcion"
              :rows="2"
              auto-grow
            />
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="objective"
              rules="required"
            >
              <v-text-field
                v-model="objective"
                label="Objetivo"
                :error-messages="errors"
                :success="valid"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="type"
              :rules="`required|oneOf:${projectTypesString}`"
            >
              <v-select
                v-model="type"
                :items="projectTypes"
                label="Tipo"
                :error-messages="errors"
                :success="valid"
                :loading="typesLoading"
                :selectable="t => t.disabled"
                class="mb-2"
              />
            </ValidationProvider>
            <v-dialog
              v-model="dialogColorPicker"
              max-width="300px"
            >
              <template v-slot:activator="{ on }">
                <span class="ml-2">Seleccionar color</span>
                <v-btn
                  :color="color"
                  v-on="on"
                />
              </template>
              <v-card>
                <v-card-title
                  class="d-flex"
                >
                  <span
                    class="text-center"
                    style="flex: 1;"
                  >
                    Selecciona un color
                  </span>
                  <v-icon
                    class="ml-auto"
                    size="medium"
                    style="margin-left: auto;"
                    @click="dialogColorPicker=false"
                  >
                    close
                  </v-icon>
                </v-card-title>
                <v-color-picker
                  v-model="color"
                />
              </v-card>
            </v-dialog>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="showDialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="submitForm"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
import Swal from 'sweetalert2';
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';

export default {
  name: 'ProjectForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    dialogColorPicker: false,
    name: '',
    description: '',
    objective: '',
    color: '#1976D2',
    type: '',
    projectTypes: [],
    loading: false,
    typesLoading: false,
  }),
  computed: {
    cardTitle() {
      return this.item === null
        ? 'Crear nuevo proyecto'
        : `Editar proyecto #${this.item.id}`;
    },
    projectTypesString() {
      return this.projectTypes.map(v => v.value).join(',');
    },
    showDialog: {
      get() {
        return this.show || this.dialog;
      },
      set(val) {
        if (val === false) {
          this.resetForm();
          this.$emit('close');
        }
        this.dialog = val;
      },
    },
  },
  watch: {
    item() {
      if (this.item !== null) {
        this.name = this.item.name;
        this.description = this.item.description;
        this.objective = this.item.objective;
        this.color = this.item.color;
        this.type = this.item.type;
      }
    },
  },
  mounted() {
    this.typesLoading = true;
    this.axios.get('/api/projects/types')
      .then(({ data }) => {
        // this.projectTypes = data;
        console.log(data);
        this.projectTypes = [
          { text: 'AHP', value: 'ahp_clasico', disabled: false },
          { text: 'AHP + Ratio', value: 'ahp_mercado', disabled: false },
          { text: 'AMUVAM', value: 'amuvam_classic', disabled: true },
          { text: 'ANP (DEMATEL) + Ratio', value: 'anp_classic', disabled: true },
        ];
        this.typesLoading = false;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          Swal.fire({
            title: 'Lo sentimos',
            text: 'Su sesión ha expirado.',
            icon: 'error',
          })
            .then(() => {
              this.typesLoading = false;
              this.$emit('logout');
            });
        }
      });
  },
  methods: {
    submitForm() {
      if (this.loading) {
        return null;
      }

      this.$refs.obs.validate()
        .then((valid) => {
          if (valid) {
            if (this.item) {
              this.update();
            } else {
              this.create();
            }
          }
        });
    },
    resetForm() {
      this.dialogColorPicker = false;
      this.name = '';
      this.description = '';
      this.objective = '';
      this.color = '#1976D2';
      this.type = '';
      this.$refs.obs.reset();
    },
    create() {
      this.axios.post('/api/projects', {
        name: this.name,
        description: this.description,
        objective: this.objective,
        color: this.color,
        type: this.type,
      })
        .then((response) => {
          Swal.fire({
            title: 'Excelente',
            text: response.data.message,
            icon: 'success',
          })
            .then(() => {
              this.showDialog = false;
              this.$emit('updateList');
            });
        })
        .catch(() => {
          Swal.fire({
            title: 'Lo sentimos!',
            text: 'Ha ocurrido algun error.',
            icon: 'error',
          });
        });
    },
    update() {
      this.axios.put(`/api/projects/${this.item.id}`, {
        name: this.name,
        description: this.description,
        objective: this.objective,
        color: this.color,
        type: this.type,
      })
        .then((response) => {
          Swal.fire({
            title: 'Excelente',
            text: response.data.message,
            icon: 'success',
          })
            .then(() => {
              this.showDialog = false;
              this.$emit('updateList');
            });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            Swal.fire({
              title: 'Lo sentimos!',
              text: error.response.data.message,
              icon: 'error',
            });
          } else {
            Swal.fire({
              title: 'Lo sentimos!',
              text: 'Ha ocurrido algun error.',
              icon: 'error',
            });
          }
        });
    },
  },
};
</script>
