<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-main>
      <v-row
        align="center"
        justify="center"
        style="height: 100%;"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
        >
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>Recuperar contraseña</v-toolbar-title>
            </v-toolbar>
            <ValidationObserver
              ref="obs"
            >
              <v-form
                id="forgot-password-form"
                ref="forgotPasswordForm"
                @submit.prevent="submit"
              >
                <v-card-text>
                  <p class="body-1">Le enviaremos un enlace a su correo electrónico para restablecer
                  su contraseña.<br />
                  Por favor, ingrese su correo a continuación: </p>
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="email"
                    rules="required|email|max:30"
                  >
                    <v-text-field
                      v-model="email"
                      label="Email"
                      :error-messages="errors"
                      :success="valid"
                      name="login"
                      prepend-inner-icon="mdi-at"
                      type="text"
                      outlined
                    />
                  </ValidationProvider>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="red"
                    class="white--text"
                    to="/login"
                  >Volver</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    type="submit"
                  >Enviar enlace</v-btn>
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import Swal from 'sweetalert2';

export default {
  name: 'ForgotPassword',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    email: '',
    loading: false,
  }),
  methods: {
    submit() {
      if (!this.$refs.obs.validate()) {
        return;
      }

      this.loading = true;
      this.axios.post('/api/forgot-password', {
        email: this.email,
      })
        .then(() => {
          Swal.fire({
            title: '¡Listo!',
            text: 'Se ha enviado un enlace a su correo electrónico para restablecer su contraseña.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          }).then(() => {
            this.$router.push('/login');
          });
        })
        .catch((error) => {
          Swal.fire({
            title: '¡Error!',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
