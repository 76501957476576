var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{staticClass:"parentToChildrenLine",staticStyle:{"z-index":"2","position":"absolute","pointer-events":"none"},style:({
    left: _vm.left+'px',
    top: '80px',
    width: '2px',
    'transform-origin': 0,
    height: 'inherit',
  }),attrs:{"width":"1","stroke":"black"}},[_c('path',{attrs:{"stroke-dasharray":"5, 2","stroke-width":"1","d":"M 0 23 L 0 0","fill":"none"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }