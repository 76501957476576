<template>
  <div>
    <v-container
      fluid
      class="fill-height"
    >
      <v-content>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="8"
          >
            <v-card class="elevation-12">
              <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="5"
                class="elevation-1"
                no-data-text="No hay proyectos registrados."
                :loading="loading"
                loading-text="Cargando proyectos registrados."
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                    color="white"
                  >
                    <v-btn
                      color="primary"
                      dark
                      @click="openForm"
                    >
                      Nuevo proyecto
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    color="#63C891"
                    @click="editItem(item)"
                  >
                    edit
                  </v-icon>
                  <v-icon
                    small
                    class="mr-2"
                    color="#F99191"
                    @click="deleteItem(item)"
                  >
                    delete
                  </v-icon>
                  <v-icon
                    small
                    color="#5EBEB7"
                    @click="seeProject(item)"
                  >
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-content>
    </v-container>
    <project-form
      :item="selectedItem"
      :show="showForm"
      @close="showForm = false"
      @updateList="updateList"
    />
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import ProjectForm from '@/components/Project/ProjectForm.vue';

export default {
  name: 'Projects',
  components: {
    ProjectForm,
  },
  data: () => ({
    headers: [
      { text: 'Nombre', value: 'name', align: 'center' },
      { text: 'Descripción', value: 'description', align: 'center' },
      { text: 'Objetivo', value: 'objective', align: 'center' },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
    items: [],
    showForm: false,
    selectedItem: null,
    loading: false,
  }),
  watch: {
    showForm(val) {
      if (val === false) {
        this.selectedItem = null;
      }
    },
  },
  mounted() {
    this.updateList();
  },
  methods: {
    editItem(item) {
      this.selectedItem = item;
      this.openForm();
    },
    deleteItem(item) {
      Swal.fire({
        title: '¿Estas seguro?',
        text: 'Esta acción no se puede deshacer...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.value) {
          this.axios.delete(`/api/projects/${item.id}`)
            .then((response) => {
              if (response.status === 204) {
                Swal.fire(
                  'Eliminado!',
                  'El proyecto ha sido eliminado correctamente.',
                  'success',
                )
                  .then(() => {
                    this.updateList();
                  });
              }
            })
            .catch((error) => {
              if (error.response.status === 404) {
                this.updateList();
                Swal.fire(
                  'Error',
                  'El proyecto que ha tratado de eliminar no existe',
                  'error',
                );
              } else {
                Swal.fire(
                  'Error',
                  'Error inesperado',
                  'error',
                );
              }
            });
        } else {
          Swal.fire(
            'Cancelado',
            'El proyecto no ha sido eliminado.',
            'success',
          );
        }
      });
    },
    seeProject(item) {
      this.$router.push(`/proyecto/${item.id}`);
    },
    openForm() {
      this.showForm = true;
    },
    updateList() {
      this.loading = true;
      this.axios.get('/api/projects')
        .then((response) => {
          this.loading = false;
          if (response.status === 200) {
            this.items = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          if (error.response.status === 401) {
            Swal.fire({
              title: 'Lo sentimos',
              text: 'Su sesión ha expirado.',
              icon: 'error',
            })
              .then(() => {
                this.$emit('logout');
              });
          }
        });
    },
  },
};
</script>
