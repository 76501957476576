<script>

import { Doughnut, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  name: 'Doughnut',
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    options: {
      required: false,
      default: () => ({}),
    },
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.chartOptions = {
      ...this.options,
      borderWidth: '10px',
      borderColor: '#1e1e1e',
      hoverBackgroundColor: 'red',
      hoverBorderWidth: '10px',
      tooltips: {
        borderWidth: 20,
        callbacks: {
          label: function(tooltipItem, data) {
            const index = tooltipItem.index;
            let text = `${data.labels[index]}: ${data.datasets[0].data[index]}%`;
            const remain = text.substr(text.match(/(.{0,30}\s?)/g).join('').length);
            return [...text.match(/(.{0,30}\s?)/g), remain].filter(val => val.length);
          },
        },
      },
    };
    this.renderChart(this.chartData, this.chartOptions);
  },
};

</script>
