import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Unverified from '@/views/Unverified.vue';
import VerifyEmail from '@/views/VerifyEmail.vue';
import Projects from '@/views/Projects.vue';
import Register from '@/views/Register.vue';
import Variables from '@/views/Variables.vue';
import Contacts from '@/views/Contacts.vue';
import ProjectViewer from '@/views/ProjectViewer.vue';
import Diagram from '@/components/ProjectViewer/Diagram.vue';
import Process from '@/components/ProjectViewer/Process.vue';
import Report from '@/components/ProjectViewer/Report.vue';
import Surveys from '@/components/ProjectViewer/Surveys.vue';

import SurveyViewer from '@/views/SurveyViewer.vue';
import QuantificationSurveyViewer from '@/views/QuantificationSurveyViewer.vue';

import QuantificationInstructions from '@/components/QuantificationSurveyViewer/QuantificationInstructions';
import Instructions from '@/components/SurveyViewer/Instructions.vue';

import RunSurvey from '@/components/SurveyViewer/RunSurvey.vue';
import QuantificationRunSurvey from '@/components/QuantificationSurveyViewer/QuantificationRunSurvey';

import SurveyDiagram from '@/components/SurveyViewer/SurveyDiagram.vue';

import Test2 from '@/views/Test2.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/test',
    name: 'Test',
    component: Test2,
    meta: { },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { onlyNoAuth: true },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { onlyNoAuth: true },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { onlyNoAuth: true },
  },
  {
    path: '/unverified',
    name: 'Unverified',
    component: Unverified,
    meta: { requiresAuth: true },
  },
  {
    path: '/verify-email/:id/:hash',
    name: 'VerifyEmail',
    component: VerifyEmail,
  },
  {
    path: '/',
    name: 'Projects',
    component: Projects,
    meta: { requiresAuth: true },
  },
  {
    path: '/registrar',
    name: 'Register',
    component: Register,
    meta: { onlyNoAuth: true },
  },
  {
    path: '/variables',
    name: 'Variables',
    component: Variables,
    meta: { requiresAuth: true },
  },
  {
    path: '/contactos',
    name: 'Contacts',
    component: Contacts,
    meta: { requiresAuth: true },
  },
  {
    path: '/encuesta/:id',
    name: 'SurveyViewer',
    component: SurveyViewer,
    meta: { requiresAuth: false },
    children: [
      {
        path: 'instrucciones',
        name: 'Instructions',
        component: Instructions,
        meta: { requiresAuth: false },
      },
      {
        path: 'ejecutar',
        name: 'RunSurvey',
        component: RunSurvey,
        meta: { requiresAuth: false },
      },
      {
        path: 'diagrama',
        name: 'SurveyDiagram',
        component: SurveyDiagram,
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: '/encuesta-cuantificar/:id',
    name: 'QuantificationSurveyViewer',
    component: QuantificationSurveyViewer,
    meta: { requiresAuth: false },
    children: [
      {
        path: 'instrucciones',
        name: 'QuantificationInstructions',
        component: QuantificationInstructions,
        meta: { requiresAuth: false },
      },
      {
        path: 'ejecutar',
        name: 'RunQuantificationSurvey',
        component: QuantificationRunSurvey,
        meta: { requiresAuth: false },
      },
    ],
  },
  {
    path: '/proyecto/:id',
    name: 'ProjectViewer',
    component: ProjectViewer,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'diagrama',
        name: 'Diagram',
        component: Diagram,
        meta: { requiresAuth: true },
      },
      {
        path: 'encuestas',
        name: 'Surveys',
        component: Surveys,
        meta: { requiresAuth: true },
      },
      {
        path: 'procesar',
        name: 'Process',
        component: Process,
        meta: { requiresAuth: true },
      },
      {
        path: 'reporte',
        name: 'Report',
        component: Report,
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const onlyNoAuth = to.matched.some(record => record.meta.onlyNoAuth);
  const isVerifyRoute = to.matched.some(record => record.name === 'Unverified');

  if (requiresAuth) {
    if (store.getters.isAuth) {
      if (store.getters.isUserEmailVerified !== isVerifyRoute) {
        next();
      } else {
        next(store.getters.isUserEmailVerified ? '/' : { name: 'Unverified' });
      }
    } else {
      next({ name: 'Login' });
    }
  } else if (onlyNoAuth) {
    if (store.getters.isAuth) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
