import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
});
/*
function findNode(tree, id) {
  if (tree.id === id) {
    return tree;
  }

  for (let i = 0; i < tree.children.length; i++) {
    findNode(tree.children[i], id);
  }

  return null;
}
*/
function findParentOfNode(tree, id) {
  if (tree.children.map(v => v.id).includes(id)) {
    return tree;
  }

  for (let i = 0; i < tree.children.length; i += 1) {
    const result = findParentOfNode(tree.children[i], id);
    if (result) return result;
  }

  return null;
}

function getNodeChildrens(node) {
  const queue = [node];
  const output = [];

  while (queue.length > 0) {
    const pointer = queue.shift();
    output.push(pointer);

    for (let i = 0; i < pointer.children.length; i += 1) {
      queue.push(pointer.children[i]);
    }
  }

  return output;
}
/*
function swapNodes(parentNode, indexA, indexB) {
  const c = parentNode.children[indexA];
  parentNode.children[indexA] = parentNode.children[indexB];
  parentNode.children[indexB] = c;
  return parentNode;
}
*/

export default new Vuex.Store({
  state: {
    token: '',
    user: null,
    drawer: true,
    project: null,
    temporaryDrawer: false,
    appBarSize: null,
    selectedNode: null,
    parentNode: null,
    availableVariables: [],
  },
  getters: {
    getField,
    isAuth: state => !!state.token,
    isUserEmailVerified: state => state.user?.email_verified_at != null,
  },
  mutations: {
    updateField,
    LOGOUT(state) {
      state.token = '';
      state.user = null;
    },
    SELECT_NODE(state, node) {
      state.selectedNode = node;
      state.parentNode = findParentOfNode(state?.project?.diagram, node.id);
    },
    REMOVE_NODE(state) {
      const parentNode = findParentOfNode(state?.project?.diagram, state.selectedNode.id);
      const nodeIndex = parentNode.children.findIndex(v => v.id === state.selectedNode.id);
      parentNode.children.splice(nodeIndex, 1);
      parentNode.name = parentNode.name.trim();
      parentNode.name += ' ';
    },
    DESELECT_NODE(state, payload) {
      if (state.selectedNode?.id === payload.id) {
        state.selectedNode = null;
        state.parentNode = null;
      }
    },
    MOVE_NODE(state, direction) {
      const node = state.selectedNode;
      const parentNode = findParentOfNode(state?.project?.diagram, node.id);
      parentNode.name = parentNode.name.trim();
      parentNode.name += ' ';
      const nodeIndex = parentNode.children.findIndex(v => v.id === node.id);
      const cantMoveLeft = direction === 'left' && nodeIndex === 0;
      const cantMoveRight = direction === 'right' && nodeIndex === parentNode.children.length - 1;
      const onlyOneChildren = parentNode.children.length === 1;

      if (cantMoveLeft || cantMoveRight || onlyOneChildren) {
        return null;
      }

      const otherNodeIndex = (direction === 'left')
        ? nodeIndex - 1
        : nodeIndex + 1;

      const c = parentNode.children[nodeIndex];
      parentNode.children[nodeIndex] = parentNode.children[otherNodeIndex];
      parentNode.children[otherNodeIndex] = c;
    },
    UPDATE_AVAILABLE_VARIABLES(state, payload) {
      state.availableVariables = payload;
    },
  },
  actions: {
    async updateDiagram({ state }) {
      const diagram = JSON.stringify(state?.project?.diagram);
      const {
        data: {
          message,
        },
      } = await this._vm.axios.put(`/api/projects/${state.project?.id}/diagram`, {
        diagram,
      });

      console.log(message);
    },
    selectNode({ commit }, payload) {
      commit('SELECT_NODE', payload);
    },
    deselectNode({ commit }, payload) {
      commit('DESELECT_NODE', payload);
    },
    moveNode({ commit, dispatch }, { direction }) {
      commit('MOVE_NODE', direction);
      dispatch('updateDiagram');
    },
    async addNode({ state, commit, dispatch }, nodeId) {
      const {
        data: {
          data: availableVariables,
        },
      } = await this._vm.axios.put(`/api/projects/${state.project?.id}/variable/${nodeId}`);

      commit('UPDATE_AVAILABLE_VARIABLES', availableVariables);
      dispatch('updateDiagram');
    },
    async removeNode({ state, commit, dispatch }) {
      if (state.selectedNode) {
        const childrens = getNodeChildrens(state.selectedNode).map(v => v.id);

        const {
          data: {
            data: availableVariables,
          },
        } = await this._vm.axios.put(`/api/projects/${state.project?.id}/variables/`, {
          variables: [state.selectedNode.id, ...childrens],
        });
        commit('UPDATE_AVAILABLE_VARIABLES', availableVariables);
        commit('REMOVE_NODE');
        dispatch('deselectNode', state.selectedNode);
        dispatch('updateDiagram');
        return true;
      }

      return false;
    },
    async verifyEmail(_, {
      id, expires, hash, signature,
    }) {
      const response = await this._vm.axios.get(`/api/auth/verify-email/${id}/${hash}`, {
        params: {
          expires,
          signature,
        },
      });

      return response;
    },
  },
  plugins: [vuexLocalStorage.plugin],
});
