<template>
  <svg
    class="parentLine"
    width="1"
    style="z-index: 2; position: absolute; pointer-events: none;"
    :style="{
      left: left+'px',
      top: '-40',
      width: finalXAbs+'px',
      'transform-origin': 0,
      transform: toLeft ? 'rotateY(180deg)' : null,
      height: 'inherit',
    }"
    stroke="black"
  >
    <path
      stroke-dasharray="3,1"
      stroke-width="1"
      d="M 0 39 L 0 0"
      fill="none"
    />
  </svg>
</template>

<script>
export default {
  name: 'ParentLine',
  props: {
    left: Number,
    finalX: Number,
  },
  computed: {
    finalXAbs() {
      return Math.abs(this.finalX) < 1
        ? 1
        : Math.abs(this.finalX);
    },
    toLeft() {
      return this.finalX < 0;
    },
  },
};
</script>
