import Vue from 'vue';
import './plugins/axios';
import {
  required,
  email,
  max,
  min,
  oneOf,
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import Vuebar from 'vuebar';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

extend('required', {
  ...required,
  message: 'Este campo es obligatorio.',
});

extend('max', {
  ...max,
  message: 'Este campo no debe tener más de {length} carácteres.',
});

extend('min', {
  ...min,
  message: 'Este campo debe tener al menos {length} carácteres.',
});

extend('email', {
  ...email,
  message: 'Este email es inválido.',
});

extend('oneOf', {
  ...oneOf,
  message: 'La opción seleccionada no es válida.',
});

const hasMinLength = (value, { mininum } = {}) => value.length >= Number(mininum);
const paramNames = ['mininum'];

extend('minLength', {
  validate: hasMinLength,
  message: 'El campo debe tener al menos {mininum} elementos',
  params: paramNames,
});

Vue.use(Vuebar);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
