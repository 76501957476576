<template>
  <v-row
    align="center"
    justify="center"
    class="custom-v-row"
  >
    <v-col
      cols="12"
      md="8"
    >

    <v-card
      class="elevation-6 mb-6"
      style="text-align:center"
      v-if="surveyNotFound"
    >
      <v-icon
        class="pt-6"
        color="red"
        x-large
      >
        mdi-alert
      </v-icon>
      <p class = "project-name">Encuesta no encontrada</p>
      <p class = "pb-5">
        Es posible que la encuesta haya sido removida o el proyecto ya no este disponible
      </p>
    </v-card>

    <v-card v-else class="elevation-6 mb-6">

      <div class = "instructions-title">

        <template v-if="fetchingSurveyInfo">
          <v-skeleton-loader
            style="background-color:gray"
            type="list-item-two-line"
          ></v-skeleton-loader>
        </template>

        <template v-else>
          <p class = "project-name">Encuestas de cuantificacion</p>
          <p class = "project-name">{{surveysInfo.data.project.name}}</p>
          <p class = "survey-creator">
          <b>{{surveysInfo.data.user.name}}</b> compartio esta encuesta contigo
          </p>
          <p v-if="surveysInfo.data.survey.finished_at" class = "survey-creator">
            <v-chip
              class="mb-2"
              color="error"
              pill>
              Esta encuesta ya ha finalizado
            </v-chip>
          </p>
        </template>

      </div>

      <div class = "container">

        <p class = "section-title mb-2">
          <b>Objetivo</b>
        </p>
        <v-divider class = "mb-2"></v-divider>

        <template v-if="fetchingSurveyInfo">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </template>

        <template v-else>
          <p class = "section-title mb-9">
            Cuantificar variables cualitativas del proyecto <b>
            {{surveysInfo.data.project.name}}</b><br><br>
          </p>
        </template>

        <template v-if="fetchingSurveyInfo">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </template>

        <template v-else>
          <div style="text-align:center"> Objetivo del proyecto</div>
          <v-divider></v-divider>
          <p class = "section-title mb-9 mt-2">
            <b>{{surveysInfo.data.project.objective}}</b><br><br>
          </p>
        </template>

        <template v-if="fetchingSurveyInfo">
          <v-skeleton-loader
            type="list-item-three-line,list-item-three-line,list-item-three-line"
          ></v-skeleton-loader>
        </template>

        <template v-else>
          <div
            v-if="!surveysInfo.data.survey.finished_at"
            class="mb-10"
          >
            <p class = "section-title">
              Instrucciones
              <v-divider></v-divider>
            </p>
            <p class = "paragraph">
              Se le presentará una serie de comparaciones que permitiran cuantificar las variables
              cualitativas del proyecto, tanto libres como restringidas.<br><br>

              Para las variables libres, debera realizar comparaciones entre comparables.
              Para las variables restringidas, debera realizar comparaciones entre categorias.
              <br><br>

              En ambos casos deberá seleccionar la variable que usted considere como más
              importante. Seguidamente, deberá determinar qué tan importante es esa variable sobre
              la otra en una escala del 1 al 9. Se recomienda realizar
              una revisión general al finalizar la encuesta de al menos dos veces para
              confirmar las respuestas y evitar inconsistencias.
              <br><br>
              En el caso de que se presenten inconsistencias en la encuesta una vez finalizada lo
              determinaremos y te lo haremos saber, de forma que puedas
              revisar nuevamente tu encuesta para que puedas asegurarte de cumplir con
              la consistencia y puedas enviar la encuesta.
            </p>
          </div>
        </template>

        <template v-if="fetchingSurveyInfo">
          <v-skeleton-loader
            type="list-item-three-line,list-item-three-line,list-item-three-line"
          ></v-skeleton-loader>
        </template>

        <br><br>

        <div class = "start-button mb-2">
          <v-btn
            color="primary"
            dark
            @click="startSurvey"
          >
          <template v-if="!fetchingSurveyInfo && surveysInfo.data.survey.finished_at">
            Ver resultados
          </template>
          <template v-else>Comenzar encuesta</template>
        </v-btn>
        </div>

      </div>

    </v-card>

    </v-col>
  </v-row>
</template>

<script>

import { mapFields } from 'vuex-map-fields';
import InstructionsVarDescription from '../InstructionsVarDescription.vue';

export default {
  name: 'Instructions',
  components: {
    InstructionsVarDescription,
  },
  data: () => ({
    surveysInfo: null,
    fetchingSurveyInfo: true,
    surveyNotFound: false,
  }),
  async mounted() {
    this.surveyStarted = false;
    await this.fetchSurveyInfo();
  },
  computed: {
    ...mapFields({
      QSurveyStarted: 'QSurveyStarted',
      QSurveyIsFinished: 'QSurveyIsFinished',
    }),
    currentSurvey() {
      if (this.surveysInfo) {
        return 'a';
      } return null;
    },
  },
  methods: {
    async fetchSurveyInfo() {
      this.fetchingSurveyInfo = true;

      try {
        this.axios
          .get(`/api/variable-surveys/${this.$route.params.id}`)
          .then((response) => {
            if ([200, 204, 201].includes(response.status)) {
              this.$set(this, 'surveysInfo', response.data);
              console.log(this.surveysInfo);
              this.fetchingSurveyInfo = false;
              this.findOutIfSurveyIsFinished();
              this.surveyIsStarted();
            }
          })
          .catch((response) => {
            if (response.response.status === 404) {
              this.$set(this, 'surveyNotFound', true);
              this.surveyStarted = false;
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    surveyIsStarted() {
      this.$set(this, 'QSurveyStarted', this.surveysInfo.data.survey.started_at !== null);
    },
    findOutIfSurveyIsFinished() {
      this.$set(this, 'QSurveyIsFinished', this.surveysInfo.data.survey.finished_at !== null);
    },
    startSurvey() {
      this.surveyStarted = true;
      this.$router.push({ name: 'RunQuantificationSurvey', params: { id: this.$route.params.id } });
    },
  },
  errorCaptured(err, vm, info) {
    this.error = `${err}\n\nfound in ${info} of component`;
    return false;
  },
};
</script>

<style>

.custom-v-row {
  height: calc(100vh - 98px);
  overflow-y: scroll;
  margin: 0;
}

.start-button {
  margin-top: 3%;
  text-align: center;
}

div.instructions-title {
  margin-top: 1em;
  text-align: center;
  background-color: #E8E8E8;
  width: 100%;
}

p.project-name {
  padding-top: 10px;
  color: #5C5C5C;
  font-weight: bold;
  font-size:1.1em;
}

p.survey-creator {
  padding-bottom: 10px;
}

p.section-title {
  font-size:1.09em;
  color: #5C5C5C;
}

p.paragraph {
  text-align: justify;
  margin: 2% 5% 2% 5%;
}

</style>
