<template>
  <div class="fill-height">
    <v-toolbar>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="showVariableForm = true"
            :disabled="diagramIsLocked"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </template>
        <span>Crear variable</span>
      </v-tooltip>

      <v-divider vertical></v-divider>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="moveNode('left')"
            class="nodeControl"
            :disabled="diagramIsLocked"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </template>
        <span>Mover nodo a la izquierda</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="removeNode" :disabled="diagramIsLocked">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Eliminar nodo</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="moveNode('right')"
            class="nodeControl"
            :disabled="diagramIsLocked"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
        <span>Mover nodo a la derecha</span>
      </v-tooltip>

      <v-divider vertical></v-divider>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="lockConfirmation"
            :disabled="diagramIsLocked"
          >
            <v-icon>mdi-lock</v-icon>
          </v-btn>
        </template>
        <span>Bloquear diagrama</span>
      </v-tooltip>
    </v-toolbar>

    <v-row no-gutters style="height: calc(100% - 74px)">
      <v-col md="10">
        <div id="tree-parent">
          <template v-if="loadingDiagram">
            <v-skeleton-loader
              style="padding: 2em; margin: 0 auto"
              type="table"
            ></v-skeleton-loader>
          </template>
          <template v-else>
            <tree
              :tree.sync="diagram"
              :class="{
                diagramIsLocked: diagramIsLocked,
              }"
            />
          </template>
        </div>
      </v-col>
      <v-col md="2">
        <v-list v-if="!diagramIsLocked" elevation="24" class="fill-height">
          <v-list-item color="purple">
            <v-list-item-content>
              <v-list-item-title class="text-center"> Variables disponibles </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <template v-if="loadingVariables">
            <v-skeleton-loader
              type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
            ></v-skeleton-loader>
          </template>
          <template v-else>
            <v-virtual-scroll
              v-if="formattedVariables.length > 0"
              :items="formattedVariables"
              class="fill-height"
              height="calc(100vh - 212px)"
              item-height="64"
            >
              <template v-slot:default="{ item }">
                <v-list-item
                  two-line
                  link
                  draggable
                  @drag="onDrag"
                  @dragstart="onDragStart($event, item)"
                  @dragend="onDragEnd"
                >
                  <v-list-item-content color="#fff">
                    <v-list-item-title class="text-center">
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-center">
                      {{ item.type }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-virtual-scroll>
            <div v-else class="pa-4 text-center text-caption">No hay más variables disponibles</div>
          </template>
        </v-list>
        <template v-else>
          <div class="pa-4 d-flex flex-column">
            <h4 class="text-center">Diagrama bloqueado</h4>
            <hr class="my-2" />
            <v-icon class="my-3" :size="100"> mdi-lock </v-icon>
            <p class="text-caption text-justify">
              Este diagrama se encuentra actualmente bloqueado, puedes desbloquearlo para seguir
              trabajando, se perderá cualquier progreso en etapas posteriores.
            </p>
            <v-btn outlined small color="red darken-1" @click="unlockDialog = true"
              >Desbloquear</v-btn
            >
          </div>
        </template>
      </v-col>
    </v-row>

    <v-dialog v-model="lockDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline text-center"> ¿Desea bloquear el diagrama? </v-card-title>

        <v-card-text class="text-justify">
          Si ya has culminado con la jerarquización de tus variables podrás bloquear el diagrama y
          pasar a la siguiente etapa de el proyecto. Recuerda que no podrás volver a editar el
          diagrama sin borrar todo el trabajo previo.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="lockDialog = false"> Cancelar </v-btn>

          <v-btn color="blue darken-1" text @click="lockDiagram"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unlockDialog" max-width="450px">
      <v-card>
        <v-card-title class="headline text-center"> ¿Desea desbloquear el diagrama? </v-card-title>

        <v-card-text class="text-justify">
          Puedes desbloquear tu diagrama, de forma que podrás gestionar nuevamente las variables en
          tu proyecto, su posición y organización dentro de el diagrama.
        </v-card-text>

        <v-card-text>
          <strong>¡Ten cuidado!</strong>
          Hacer esto hará que todo el progreso que tengas en las siguientes etapas se pierda,
          incluyendo cosas que estén compartidas con tus contactos (como las encuestas).
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="unlockDialog = false"> Cancelar </v-btn>

          <v-btn color="blue darken-1" text @click="unlockDiagram"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <variable-form
      :show="showVariableForm"
      @close="showVariableForm = false"
      @updateList="loadAvailableVariables"
    />

    <div id="dragItem" class="node elevation-12" ref="dragItem" :class="{ show: drag }">
      <div class="node-text">
        {{ dragItem.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import VariableForm from '@/components/Variable/VariableForm.vue';
import Tree from '../Tree.vue';

export default {
  name: 'Diagram',
  components: {
    Tree,
    VariableForm,
  },
  data: () => ({
    loadingDiagram: false,
    loadingVariables: true,
    searchVariableQuery: '',
    showVariableForm: false,
    drag: false,
    transparentImg: null,
    dragItem: {
      id: null,
      name: '',
      type: '',
    },
    dragItemIndex: null,
    timer: null,
    delay: 10,
    lockDialog: false,
    unlockDialog: false,
  }),
  async mounted() {
    this.transparentImg = new Image();
    this.transparentImg.src = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    this.selectedNode = null;
    await this.loadAvailableVariables();
    // await this.loadDiagram();
  },
  computed: {
    ...mapFields({
      diagram: 'project.diagram',
      diagramIsLocked: 'project.diagram_locked',
      availableVariables: 'availableVariables',
      selectedNode: 'selectedNode',
    }),
    formattedVariables() {
      if (this.searchVariableQuery.trim() === '') {
        return this.availableVariables.map(v => ({
          ...v,
          meta: {
            listDisabled: this.dragItem?.id === v.id,
          },
        }));
      }

      const regex = new RegExp(this.searchVariableQuery.toUpperCase());

      return this.searchVariableQuery.trim() === ''
        ? this.availableVariables.map(v => ({
          ...v,
          meta: {
            listDisabled: this.dragItem?.id === v.id,
          },
        }))
        : this.availableVariables
          .filter(v => regex.test(v.name.toUpperCase()))
          .map(v => ({
            ...v,
            meta: {
              listDisabled: this.dragItem?.id === v.id,
            },
          }));
    },
  },
  methods: {
    async lockDiagram() {
      try {
        await this.axios.post(`/api/projects/${this.$route.params.id}/lock_diagram`);
        this.$emit('reloadProjectData');
        this.lockDialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    async unlockDiagram() {
      try {
        await this.axios.post(`/api/projects/${this.$route.params.id}/unlock_diagram`);
        this.$emit('reloadProjectData');
        this.unlockDialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    async moveNode(direction) {
      await this.$store.dispatch('moveNode', { direction });
    },
    async removeNode() {
      const removed = await this.$store.dispatch('removeNode');

      if (removed) {
        await this.loadAvailableVariables();
      }
    },
    lockConfirmation() {
      this.lockDialog = true;
    },
    onDrag(evt) {
      if (this.timer) return;

      this.timer = setTimeout(() => {
        this.timer = null;
        this.$refs.dragItem.style.left = `${evt.x - 55}px`;
        this.$refs.dragItem.style.top = `${evt.y - 25}px`;
      }, this.delay);
    },
    onDragStart(evt, item) {
      this.$set(this, 'dragItem', item);

      this.dragItemIndex = this.availableVariables.findIndex(v => v.id === item.id);

      // this.availableVariables.splice(this.dragItemIndex, 1);

      evt.dataTransfer.setData(
        'text/plain',
        JSON.stringify({
          type: 'node',
          data: {
            id: item.id,
            name: item.name,
            type: item.type,
            children: [],
          },
        }),
      );

      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.setDragImage(this.transparentImg, 0, 0);
      this.drag = true;
      this.$refs.dragItem.style.left = `${evt.x - 55}px`;
      this.$refs.dragItem.style.top = `${evt.y - 25}px`;
      this.$refs.dragItem.style.display = 'block';
    },
    onDragEnd(evt) {
      // If the drop is cancelled
      if (evt.dataTransfer.dropEffect === 'none') {
        // this.availableVariables.splice(this.dragItemIndex, 0, this.dragItem);
      }

      this.$set(this, 'dragItem', {
        id: null,
        name: '',
        type: '',
      });

      this.dragItemIndex = null;

      this.drag = false;
      this.$refs.dragItem.style.display = 'none';
    },
    async loadAvailableVariables() {
      this.loadingVariables = true;

      try {
        const response = await this.axios.get(`/api/projects/${this.$route.params.id}/variables`);

        console.log(response.data);

        this.$set(this, 'availableVariables', response.data);
      } catch (error) {
        console.error(error);
      }

      this.loadingVariables = false;
    },
    async loadDiagram() {
      this.loadingDiagram = true;

      try {
        const { data: diagram } = await this.axios.get(
          `/api/projects/${this.$route.params.id}/diagram`,
        );

        this.$set(this, 'diagram', JSON.parse(diagram));
      } catch (error) {
        console.log(error);
      }

      this.loadingDiagram = false;
    },
  },
};
</script>

<style>
#dragItem {
  display: none;
  position: fixed;
  top: 0;
  width: 180px;
  left: 0;
  height: 80px;
  z-index: 20;
  pointer-events: none;
}

#dragItem.show {
  pointer-events: none;
  display: block;
}

#dragItem .node-text {
  justify-content: center;
  align-items: center;
  height: 80px;
  pointer-events: none;
}

.node-text {
  display: flex;
  pointer-events: none;
}

.draggable-list {
  height: calc(100vh - 212px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#tree-parent {
  height: calc(100% - 38px);
  background: #e8e9f3;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
}

#tree {
  width: fit-content;
  min-width: 100%;
}

#tree-container {
  position: relative;
}

.subtree-wrapper {
  display: grid;
  grid-auto-columns: auto;
  grid-auto-rows: 120px auto;
  grid-column-gap: 2rem;
  grid-row-gap: 1.5rem;
  justify-items: center;
  height: fit-content;
}

.node {
  grid-area: 1 / 1 / 2 / -1;
  border-radius: 5px;
  width: 180px;
  height: 80px;
  display: flex;
  position: relative;
  cursor: pointer;
  background: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0;
  transition: border ease-in-out 0.2s;
}

.node-text {
  user-select: none;
  text-align: center;
}

.children-wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.child {
  flex: 1;
}

.diagramIsLocked > * {
  pointer-events: none;
}

.diagramIsLocked:hover {
  cursor: not-allowed;
}
</style>
