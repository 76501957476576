<template>
  <v-dialog
    transition="dialog-bottom-transition"
    :key="showDialog"
    v-model="showDialog"
    :max-width='getMaxWidth()'
    max-height="450px"
  >
    <v-card>
      <v-carousel
        v-if="surveyResponsesInfo"
        v-model="model"
        height="100%"
        hide-delimiter-background
        @change="fetchSurveyStatus"
        show-arrows-on-hover
        :light="true"
      >
        <v-carousel-item
          v-for="(index) in paginationInfo.total"
          :key="index"
        >
          <v-sheet
            height="100%"
            tile
          >
          <v-row class="mb-0" justify="space-around" no-gutters>
              <v-col style="vertical-align:center">

                <v-row justify="center">
                  <div class="mt-6 mb-0">
                    Criterio <b>{{surveyResponsesInfo.pca.name}}</b>
                  </div>
                </v-row>

                <v-row justify="center" :class="getComparisonsTableMarginClass()">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Variable más importante
                          </th>
                          <th class="text-center">
                            Magnitud
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in surveyResponsesInfo.comparisons"
                          :key="item.id"
                        >
                          <td class="comparisonVariablesButtons mt-2" style="text-align:center">
                            <v-tooltip v-if="needToolTip(item.variable_a)" left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-on="on"
                                  class="mr-1 variable-chip"
                                  color="info"
                                  style="text-align:center"
                                  :outlined="item.a_major_than_b !== true"
                                  pill
                                  label>
                                  {{item.variable_a}}
                                </v-chip>
                              </template>
                              <span>
                                {{item.variable_a}}
                              </span>
                            </v-tooltip>
                            <v-chip
                              v-else
                              class="mr-1 variable-chip"
                              color="info"
                              style="text-align:center"
                              :outlined="item.a_major_than_b !== true"
                              pill
                              label>
                              {{item.variable_a}}
                            </v-chip>

                            <v-tooltip v-if="needToolTip(item.variable_b)" right>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-on="on"
                                  class="ml-1 variable-chip"
                                  color="info"
                                  style="text-align:center"
                                  :outlined="item.a_major_than_b !== false"
                                  pill
                                  label>
                                  {{item.variable_b}}
                                </v-chip>
                              </template>
                              <span>
                                {{item.variable_b}}
                              </span>
                            </v-tooltip>
                            <v-chip
                              v-else
                              class="ml-1 variable-chip"
                              color="info"
                              style="text-align:center"
                              :outlined="item.a_major_than_b !== false"
                              pill
                              label>
                              {{item.variable_b}}
                            </v-chip>

                          </td>
                          <td>{{ item.magnitude | noInfo}}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
              </v-col>

              <v-col v-if="showGraph" class="mb-12">
                <div class="g-per">
                  CR {{surveyResponsesInfo.pca.cr.value}} % <br>
                  CR max {{surveyResponsesInfo.pca.cr.max}} %
                </div>
                <div class="mt-6 mb-6" style="text-align:center">
                  Proporciones del vector propio
                </div>
                <div class= "container" style="position:relative;height:350px;max-width:350px">
                  <pie-chart :chart-data="chartData"/>
                </div>
              </v-col>

          </v-row>
          <v-row v-if="showGraph" style="width:80%;margin-left:10%"
          justify="space-around" no-gutters>
            <v-col>
              <div class="matrix mb-9">
                  <div class="mb-6" style="text-align:center">
                    Matriz {{getVariablesQuantity()}} x {{getVariablesQuantity()}}
                  </div>

                  <div
                    class="wrapper"
                    :style="getWrapperStyle(getVariables())"
                  >
                    <div class="grid-item-tag"></div>
                    <div class="grid-item-variable"
                      v-for="variable in variables" v-bind:key="`${variable}-name1`">
                      {{variable}}
                    </div>
                    <div class="grid-item-tag">
                      VP
                    </div>
                    <template v-for="(variable, index) in variables">
                      <div v-bind:key="`${variable}-name2`" class="grid-item-variable">
                        {{variable}}
                      </div>
                      <template
                        v-for="comparisonNumber in getComparisonsArrayByVariable(variable)"
                      >
                        <div v-bind:key="generateRandomKeyForATr()" class="grid-item">
                          {{roundNumber(comparisonNumber, 4)}}
                        </div>
                      </template>
                      <div class="grid-item-vp" v-bind:key="`${variable}-value`">
                        {{roundNumber(chartData.datasets[0].data[index] / 100, 4)}}
                      </div>
                    </template>
                  </div>
                </div>
            </v-col>
          </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
import { mapFields } from 'vuex-map-fields';

import PieChart from '@/components/PieChart.vue';


export default {
  name: 'SurveyStatusModal',
  components: {
    PieChart,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    dialog: false,
    aditionalColumns: 2,
    currentSelectedUser: null,
    surveysInfo: null,
    surveyResponsesInfo: null,
    paginationInfo: null,
    showGraph: false,
    alternatives: null,
    model: 0,
    colors: [
      'primary',
      'secondary',
      'yellow darken-2',
      'red',
      'orange',
    ],
    chartOptions: {
      responsive: false,
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
        }],
      },
    },
    chartData: {
      labels: [],
      datasets: [
        {
          backgroundColor: [
            '#ebac23',
            '#b80058',
            '#008cf9',
            '#006e00',
            '#00bbad',
            '#d163e6',
            '#b24502',
            '#ff9287',
            '#5954d6',
            '#00c6f8',
            '#878500',
            '#00a76c',
            '#bdbdbd',
          ],
          data: [],
        },
      ],
    },
  }),
  filters: {
    noInfo(value) {
      if (!value) return '-';
      return value;
    },
  },
  methods: {
    generateRandomKeyForATr() {
      return (Math.random() + 1).toString(36).substring(2);
    },
    needToolTip(variable) {
      return variable.length > 11;
    },
    getComparisonsTableMarginClass() {
      return this.showGraph ? 'mb-0' : 'mb-16';
    },
    getMaxWidth() {
      if (this.surveyResponsesInfo) {
        if (this.showGraph === false) {
          return '500px';
        } return '1000px';
      }
      return '500px';
    },
    getComparisonsArrayByVariable(variable) {
      const comparisons = [];

      for (let i = 0; i < this.variables.length; i += 1) {
        if (this.variables[i] === variable) {
          comparisons.push(1);
        } else {
          const mostImportant = this.getMostImportantVar(this.variables[i], variable);
          if (mostImportant.variable === variable) {
            comparisons.push(this.roundNumber(mostImportant.magnitude, 4));
          } else {
            comparisons.push(this.roundNumber(1 / mostImportant.magnitude, 4));
          }
        }
      }

      return comparisons;
    },
    getMostImportantVar(variablea, variableb) {
      for (let i = 0; i < this.surveyResponsesInfo.comparisons.length; i += 1) {
        const comparison = this.surveyResponsesInfo.comparisons[i];

        if (comparison.variable_a === variablea && comparison.variable_b === variableb) {
          if (comparison.a_major_than_b) {
            return { variable: variablea, magnitude: comparison.magnitude };
          }
          return { variable: variableb, magnitude: comparison.magnitude };
        } if (comparison.variable_a === variableb && comparison.variable_b === variablea) {
          if (comparison.a_major_than_b) {
            return { variable: variableb, magnitude: comparison.magnitude };
          }
          return { variable: variablea, magnitude: comparison.magnitude };
        }
      }
      return null;
    },
    getVariablesQuantity() {
      if (this.alternatives) {
        return this.alternatives.length;
      } return 0;
    },
    getVariables() {
      if (this.alternatives) {
        return this.alternatives.map(alternative => alternative.name);
      } return null;
    },
    roundNumber(number, places) {
      return parseFloat(`${Math.round(`${number}e+${places}`)}e-${places}`);
    },
    getWrapperStyle(n) {
      const style = {
        display: 'grid',
        'grid-template-rows': `repeat(${n.length + this.aditionalColumns}, ${n.length + this.aditionalColumns})`,
        'grid-template-columns': `repeat(${n.length + this.aditionalColumns}, auto)`,
        gap: '5px',
      };
      return style;
    },
    getLabelById(id) {
      const comparison = this.surveyResponsesInfo.comparisons.find(
        comp => comp.id === id,
      );
      console.log(id, comparison, this.surveyResponsesInfo);
      return `${comparison.variable_a} & ${comparison.variable_b}`;
    },
    async fetchSurveyStatus(page) {
      // reset graph info
      this.showGraph = false;

      this.chartData.datasets[0].data = [];

      this.chartData.labels = [];

      try {
        const response = await this.axios
          .get(`/api/surveys/${this.inviteToken}/page/${page + 1}`);

        this.$set(this, 'surveyResponsesInfo', response.data.data);
        this.$set(this, 'paginationInfo', response.data.metadata);
        this.$set(this, 'alternatives', this.surveyResponsesInfo.pca.alternatives);

        if (this.alternatives) {
          for (let i = 0; i < this.alternatives.length; i += 1) {
            this.chartData.datasets[0].data.push(
              this.roundNumber(this.roundNumber(this.alternatives[i].weight, 4) * 100, 2),
            );
            this.chartData.labels.push(
              this.alternatives[i].name,
            );
          }
          this.showGraph = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async setNecesaryInfo(user, surveysInfo) {
      this.model = 0;
      this.currentSelectedUser = user;
      this.surveysInfo = surveysInfo;
      await this.fetchSurveyStatus(0);
    },
  },
  computed: {
    ...mapFields({
      diagram: 'project.diagram',
    }),
    variables() {
      if (this.alternatives) {
        return this.alternatives.map(alternative => alternative.name);
      } return null;
    },
    inviteToken() {
      if (this.surveysInfo) {
        return this.surveysInfo.data.find(
          survey => survey.contact_id === this.currentSelectedUser.id,
        ).token;
      } return '';
    },
    showDialog: {
      get() {
        return this.show || this.dialog;
      },
      set(val) {
        if (val === false) {
          this.$emit('close');
        }
        this.dialog = val;
      },
    },
  },
};
</script>

<style scoped>


>>> .v-chip__content {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 7%;
}

.variable-chip {
  width: 100px;
  display: block;
}

.g-per {
  position:absolute;
  margin-top:245px;
  margin-left: 21%;
}

.matrix {
  border: 1px solid white;
  width: 100%;
}

.comparisonVariablesButtons {
  display: grid;
  grid-area: variableButtons;
  grid-template-columns: 50% 50%;
}

.table-row {
  display: flex;
}

.grid-item {
  border: 1px solid #D0D0D0;
  background-color: #EEEEEE;
  border-radius: 3px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.grid-item-vp {
  border: 1px solid #D0D0D0;
  background-color: #BED0FB;
  border-radius: 3px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.grid-item-variable {
  border: 1px solid #D0D0D0;
  background-color: #DEDEDE;
  border-radius: 3px;
  text-align: center;
  margin: 0;
  padding: 0;
}

.grid-item-tag {
  background-color: white;
  text-align: center;
  margin: 0;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.matrix {
  padding: 5%;
}
.grid-item {
  grid-column-start: auto;
  grid-row-start: auto;
}
.grid-item{
  margin: 0;
  padding: 0;
  border: 1px solid #E4E4E4;
}


</style>
