<template>
  <v-dialog
    :key='showDialog'
    v-model='showDialog'
    max-width='800px'
  >
    <v-card>
      <v-card-title>
        ejemplo variables
      </v-card-title>
      <v-card-text>
          <table
            class="vagn-tiny-table"
          >
            <tr>
              <td class="vagn-title">Variable X</td>
              <td class="vagn-content-row">2432423</td>
              <td class="vagn-content-row">4324234</td>
              <td class="vagn-content-row">4234324</td>
            </tr>
            <tr>
              <td class="vagn-title">variable Y</td>
              <td class="vagn-content-row">2432423</td>
              <td class="vagn-content-row">4324234</td>
              <td class="vagn-content-row">4234324</td>
            </tr>
          </table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color='red darken-1'
          text
          @click='showDialog = false'
        >
          cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type='text/javascript'>
import { mapFields } from 'vuex-map-fields';


export default {
  name: 'SurveyQuantificationResultsModal',
  components: {
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    dialog: false,
    currentSelectedUser: null,
    surveyInfo: null,
    matrix: null,
    surveyTree: null,
  }),
  mounted() {},
  methods: {
    generateRandomKeyForATr() {
      return (Math.random() + 1).toString(36).substring(2);
    },
    roundNumber(number, places) {
      return parseFloat(`${Math.round(`${number}e+${places}`)}e-${places}`);
    },
    setNecesaryInfo(user, surveysInfo) {
      this.currentSelectedUser = user;
      this.surveyInfo = surveysInfo.data.find(s => s.contact_id === user.id);
      this.fetchSurveyTree(this.surveyInfo.token);
    },
    async fetchSurveyTree(token) {
      this.fetchingSurveyInfo = true;
      try {
        const response = await this.axios
          .get(`/api/surveys/${token}`);

        this.preorderTrasversal(JSON.parse(response.data.data.surveyTree));
      } catch (error) {
        console.error(error);
      }
      this.fetchingSurveyInfo = false;
    },
  },
  watch: {
    show(newVal) {
      console.log(newVal);
    },
  },
  computed: {
    ...mapFields({
      diagram: 'project.diagram',
    }),
    showDialog: {
      get() {
        return this.show || this.dialog;
      },
      set(val) {
        if (val === false) {
          this.$emit('close');
        }
        this.dialog = val;
      },
    },
  },
};
</script>

<style type='text/css'>

  li {
    display: inline;
  }

  table.results-table {
    border: 1px solid white;
    width: 100%;
  }

  td,tr {
    margin: 0;
    text-align: center;
  }

  td {
    padding: 5px;
  }

  td.vp-tag {
    font-weight: bold;
  }

  td.td-pond-vp-cell {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    background-color: #BED0FB;
    word-wrap: break-word;
  }

  td.td-pond {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    background-color: #DEDEDE;
    word-wrap: break-word;
  }

  td.td-pond-variable-name {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    background-color: #EEEEEE;
    word-wrap: break-word;
  }

  tr.tr-variable {
    background-color: #EEEEEE;
    word-wrap: break-word;
  }

  .cualitative-input-cell {
    background-image: repeating-linear-gradient(
        45deg, #EEEEEE, #BED0FB 1px, transparent 2px, transparent 5px
    );
  }

  .no-comparables-found {
    color: rgba(0, 0, 0, 0.38);
  }

  .vagn-tiny-table {
    margin-top: 300px;
    width: 100%;
    margin-left: 5%;
  }

  th.vagn-title, td.vagn-title {
    border-radius: 3px;
    background-color: #BED0FB;
    border: 1px solid #929292;
    word-wrap: break-word;
    color: rgba(0, 0, 0, 0.8);
  }

  th.vagn-content-row, td.vagn-content-row {
    border-radius: 3px;
    background-color: #EEEEEE;
    border: 1px solid #D0D0D0;
    word-wrap: break-word;
  }

</style>
