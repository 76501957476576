var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subtree-wrapper"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:({
      handler: _vm.deselectNode,
      include: _vm.include,
    }),expression:"{\n      handler: deselectNode,\n      include: include,\n    }"}],ref:"parentNode",staticClass:"node elevation-6",staticStyle:{"pointer":"cursor"},style:({
      border: _vm.border,
      backgroundColor: _vm.selected ? _vm.levelColor : '#fff',
    }),on:{"click":_vm.selectNode,"drag":_vm.onDrag,"dragstart":_vm.onDragStart,"dragend":_vm.onDragEnd,"drop":_vm.onDrop,"dragover":_vm.onDragOver,"dragenter":_vm.onDragEnter,"dragleave":_vm.onDragLeave}},[_c('div',{staticClass:"node-text"},[_vm._v(" "+_vm._s(_vm._f("abbreviate")(_vm.subtree.name))+" ")]),(_vm.hasChildren)?_c('ChildrenLine',{attrs:{"left":_vm.halfX,"top":_vm.height}}):_vm._e(),_c('ParentLine',{attrs:{"left":_vm.halfX,"final-x":_vm.childToParentDistance}}),(_vm.moreThanOneChildren)?_c('ChildrenHorizontalLine',{attrs:{"children-line-start":_vm.childrenLineStart,"children-line-end":_vm.childrenLineEnd,"parent-left":_vm.nodeLeft}}):_vm._e()],1),_c('div',{staticClass:"children-wrapper"},_vm._l((_vm.children),function(child,i){return _c('div',{key:i,staticClass:"child"},[_c('node',{attrs:{"subtree":child,"parent-absolute-middle":_vm.absoluteHalfX,"children-number":i,"level":_vm.level + 1},on:{"update:parentAbsoluteMiddle":function($event){_vm.absoluteHalfX=$event},"update:parent-absolute-middle":function($event){_vm.absoluteHalfX=$event}}})],1)}),0),_c('div',{ref:"dragItem",staticClass:"node elevation-12",class:{ show: _vm.drag },attrs:{"id":"dragItem"}},[_c('div',{staticClass:"node-text"},[_vm._v(" "+_vm._s(_vm.dragItemName)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }