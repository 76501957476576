<template>
  <v-dialog
      :key='showDialog'
      v-model='showDialog'
      max-width="1100px"
    >
    <v-card>
      <v-card-title class="headline text-center">
        Encuestas para cuantificar variables cualitativas
      </v-card-title>

      <v-card-text class="text-justify">
        <v-toolbar class="elevation-0">

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="showImportContactForm = true"
                :disabled="surveysFinished"
              >
                <v-icon>mdi-account-arrow-right</v-icon>
              </v-btn>
            </template>
            <span>Importar contacto existente</span>
          </v-tooltip>

          <v-divider vertical></v-divider>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="!allSurveysFinished ||
                surveysFinished ||
                (invitedUsers.length <= 0)"
                @click="showFinishSurveysModal = true"
              >
                <v-icon>mdi-lock</v-icon>
              </v-btn>
            </template>
            <span>Finalizar encuestas de cuantificacion</span>
          </v-tooltip>
        </v-toolbar>
        <div class="fill-height">
          <v-card class="elevation-0">
            <v-data-table
              :headers="headers"
              :items="invitedUsers"
              :items-per-page="5"
              class="elevation-0"
              no-data-text="No se ha invitado a ningun experto"
              :loading="loadingContacts"
              loading-text="Cargando expertos invitados"
              :search="search"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                  color="white"
                >
                  <v-chip
                    class="mt-2"
                    color="info"
                    outlined
                    pill>
                    Expertos invitados
                  </v-chip>
                  <v-text-field
                    v-model="search"
                    label="Busca a un experto invitado"
                    class="mx-4 mt-8"
                  ></v-text-field>
                  <v-icon
                    color="primary"
                    class="button mt-6"
                    @click="fetchInitData()"
                  >
                    mdi-reload
                  </v-icon>
                </v-toolbar>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip
                  x-small
                  class="mr-5 white--text"
                  :color="getTableSatusColorByInvitedUser(item)"
                > {{ getTableSatusByInvitedUser(item) }}
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="!individualSurveyIsFinished(item)"
                  small
                  color="#F99191"
                  class="mr-2"
                  @click="uninviteUser(item)"
                >
                  delete
                </v-icon>
                <v-icon
                  small
                  color="gray"
                  class="mr-2"
                  @click="setSurveyLinkModal(item)"
                >
                  mdi-link
                </v-icon>
                <v-icon
                  v-if="!individualSurveyIsFinished(item)"
                  small
                  class="mr-2"
                  @click="$event => resendInvitation(item)"
                >
                  mdi-email-sync
                </v-icon>
                <!--<v-icon
                  v-if="resultModalAvailable(item)"
                  color="#4EB653"
                  small
                  @click="setSurveyResultsModal(item)"
                >
                  mdi-table
                </v-icon>-->
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="red darken-1"
          text
          @click="showDialog = false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="showFinishSurveysModal"
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline text-center">
          ¿Desea finalizar las encuestas?
        </v-card-title>

        <v-card-text class="text-justify">
          Si ya has culminado con la asignacion de encuestas, puedes bloquearlas para asi pasar a la
          etapa de calculos finales.
        </v-card-text>

        <v-card-text>
          <strong>No podra deshacer este paso.</strong>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            text
            @click="showFinishSurveysModal = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue darken-1"
            text
            @click="finishSurveys"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <survey-link-modal
      :show="showSurveyLinkModal"
      @close="showSurveyLinkModal = false"
      ref="surveyLinkModal"
    />

    <survey-quantification-results-modal
      :show="showSurveyResultsModal"
      @close="showSurveyResultsModal = false"
      ref="surveyResultsModal"
    />

    <contact-form
      :show="showContactForm"
      @close="showContactForm = false"
      :fromSurvey="true"
      @updateList="updateInvitedUsers"
    />

    <import-contact-form
      :show="showImportContactForm"
      @close="showImportContactForm = false"
      @updateList="updateInvitedUsers"
      ref="importContact"
    />

  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2';
import { mapFields } from 'vuex-map-fields';
import ContactForm from '@/components/Contact/ContactForm.vue';
import ImportContactForm from '@/components/Contact/ImportContactForm.vue';
import SurveyLinkModal from '@/components/SurveyLinkModal.vue';
import SurveyQuantificationResultsModal from '@/components/SurveyQuantificationResultsModal.vue';

export default {
  name: 'QuantificationSurveysModal',
  components: {
    ContactForm,
    ImportContactForm,
    SurveyLinkModal,
    SurveyQuantificationResultsModal,
  },
  data: () => ({
    headers: [
      { text: 'Nombre', value: 'name', align: 'center' },
      { text: 'Apellido', value: 'lastname', align: 'center' },
      { text: 'Email', value: 'email', align: 'center' },
      {
        text: 'Estatus',
        value: 'status',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Acciones',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
    dialog: false,
    tab: 0,
    loadingContacts: false,
    lockDialog: false,
    lockSurveys: false,
    unlockDialog: false,
    showContactForm: false,
    showImportContactForm: false,
    showSurveyStatusModal: false,
    showSurveyResultsModal: false,
    showSurveyLinkModal: false,
    showFinishSurveysModal: false,
    variableSurveysMetadata: null,
    transparentImg: null,
    availableContacts: null,
    search: '',
    surveysInfo: null,
  }),
  async mounted() {
    this.surveysInfo = null;
    this.transparentImg = new Image();
    this.transparentImg.src = 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    this.fetchInitData();
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapFields({
      project: 'project',
      diagram: 'project.diagram',
      notInvitedUsersQuantificationSurveys: 'notInvitedUsersQuantificationSurveys',
    }),
    surveysFinished() {
      return this.variableSurveysMetadata?.assessment?.surveys_finished || false;
    },
    showDialog: {
      get() {
        return this.show || this.dialog;
      },
      set(val) {
        if (val === false) {
          this.$emit('close');
        }
        this.dialog = val;
      },
    },
    invitedUsers() {
      if (this.availableContacts) {
        return this.availableContacts.filter(this.userIsInvited);
      } return [];
    },
    mainSurveysWereProcessed() {
      if (this.project && 'vp' in this.project) {
        return JSON.parse(this.project.vp).length > 0;
      } return false;
    },
  },
  watch: {
    showImportContactForm(newVal) {
      if (newVal) {
        if (this.$refs.importContact === undefined) {
          this.updateInvitedUsers();
        } else {
          this.$refs.importContact
            .setSurveyInfo(this.availableContacts.filter(this.userIsNotInvited), true);
        }
      }
    },
  },
  methods: {
    async fetchVariableSurveysMetadata() {
      try {
        const response = await this.axios
          .get(`/api/projects/${this.$route.params.id}/assessment`);

        this.$set(this, 'variableSurveysMetadata', response.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    async resendInvitation(item) {
      const survey = this.surveysInfo.data.find(s => s.contact_id === item.id);
      try {
        await this.axios
          .post(`/api/variable-surveys/${survey.token}/resend-email`);

        Swal.fire({
          title: '¡Éxito!',
          text: 'Se ha reenviado el correo de invitación',
          icon: 'success',
          confirmButtonText: 'Aceptar',
        });
      } catch (error) {
        console.error(error);

        Swal.fire({
          title: '¡Error!',
          text: 'No se ha podido reenviar el correo de invitación',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    },
    individualSurveyIsFinished(item) {
      if (this.surveysInfo) {
        if (this.surveysInfo.data.find(
          survey => survey.contact_id === item.id,
        ).status === 'finished') {
          return true;
        }
        return false;
      } return false;
    },
    async fetchInitData() {
      this.loadingContacts = true;

      await this.fetchProjectSurveyInfo();

      await this.loadAvailableContacts();

      await this.fetchVariableSurveysMetadata();
    },
    allSurveysFinished() {
      if (this.surveysInfo) {
        if (this.surveysInfo.data.length === 0) {
          return false;
        }
        return !this.surveysInfo.data.some(
          survey => survey.finished_at === null,
        );
      }
      return false;
    },
    async finishSurveys() {
      try {
        const response = await this.axios
          .post(`/api/projects/${this.$route.params.id}/finish_variable_surveys`);

        this.showFinishSurveysModal = false;

        if ([200, 204, 201].includes(response.status)) {
          this.showFinishSurveysModal = false;

          await Swal.fire(
            'Terminada',
            'Se han finalizado las encuestas de variables',
            'success',
          );

          this.fetchVariableSurveysMetadata();
        }
      } catch (error) {
        this.showFinishSurveysModal = false;

        await Swal.fire(
          'Error de finalizacion',
          error.response.data.message,
          'warning',
        );
      }
    },
    resultModalAvailable(user) {
      return this.surveysInfo.data.find(
        survey => survey.contact_id === user.id,
      ).status === 'finished';
    },
    getInviteLinkByUser(user) {
      return this.surveysInfo.data.find(
        survey => survey.contact_id === user.id,
      ).id;
    },
    getTableSatusColorByInvitedUser(item) {
      if (this.surveysInfo) {
        switch (this.surveysInfo.data.find(
          survey => survey.contact_id === item.id,
        ).status) {
          case 'not-started':
            return 'blue';
          case 'in-progress':
            return 'orange';
          case 'finished':
            return 'green';
          default:
            return '';
        }
      } return '';
    },
    getTableSatusByInvitedUser(item) {
      if (this.surveysInfo) {
        switch (this.surveysInfo.data.find(
          survey => survey.contact_id === item.id,
        ).status) {
          case 'not-started':
            return 'No iniciada';
          case 'in-progress':
            return 'En progreso';
          case 'finished':
            return 'Finalizada';
          default:
            return '';
        }
      } return '';
    },
    async updateInvitedUsers() {
      await this.fetchProjectSurveyInfo();
      await this.loadAvailableContacts();
      this.$refs.importContact
        .setSurveyInfo(this.availableContacts.filter(this.userIsNotInvited), true);
    },
    async updateNotInvitedUsers() {
      this.notInvitedUsersQuantificationSurveys = this.availableContacts
        .filter(this.userIsNotInvited);
    },
    userIsInvited(user) {
      if (this.surveysInfo != null) {
        return this.surveysInfo.data.some(
          survey => survey.contact_id === user.id,
        );
      }
    },
    userIsNotInvited(user) {
      if (this.surveysInfo != null) {
        return !this.surveysInfo.data.some(
          survey => survey.contact_id === user.id,
        );
      }
    },
    async fetchProjectSurveyInfo() {
      try {
        const response = await this.axios
          .get(`/api/projects/${this.$route.params.id}/variable-surveys`);

        this.$set(this, 'surveysInfo', response.data);
      } catch (error) {
        console.error(error);
      }
    },
    async loadAvailableContacts() {
      try {
        const response = await this.axios
          .get('/api/contacts');

        this.$set(this, 'availableContacts', response.data);
        this.loadingContacts = false;
        await this.updateNotInvitedUsers();
      } catch (error) {
        console.error(error);
      }
    },
    setCurrentContact(item) {
      this.currentSelectedUser = item;
    },
    setSurveyLinkModal(user) {
      this.$refs.surveyLinkModal.setNecesaryInfo(user, this.surveysInfo, true);
      this.showSurveyLinkModal = true;
      this.currentSelectedUser = user;
    },
    setSurveyResultsModal(user) {
      this.$refs.surveyResultsModal.setNecesaryInfo(user, this.surveysInfo);
      this.showSurveyResultsModal = true;
      this.currentSelectedUser = user;
    },
    async uninviteUser(user) {
      Swal.fire({
        title: '¿Estas seguro?',
        text: `Eliminar invitacion a encuesta de ${user.email}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!',
        cancelButtonText: 'No',
        lockBodyScroll: true,
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            this.axios.delete(`/api/variable-surveys/${this.getInviteLinkByUser(user)}`)
              .then((response) => {
                console.log(response);
                if (response.status === 204) {
                  Swal.fire(
                    'Eliminado!',
                    'Se ha eliminado la invitacion correctamente.',
                    'success',
                  )
                    .then(() => {
                      this.loadingContacts = true;
                      this.updateInvitedUsers();
                    });
                }
              })
              .catch(() => {
                Swal.fire({
                  title: 'Lo sentimos!',
                  text: 'Ha ocurrido algun error.',
                  icon: 'error',
                });
              });
          } catch (error) {
            console.error(error);
          }
        }
      });
    },
  },
};
</script>

<style type='text/css'>

  li {
    display: inline;
  }

  table.results-table {
    border: 1px solid white;
    width: 100%;
  }

  td,tr {
    margin: 0;
    text-align: center;
  }

  td {
    padding: 5px;
  }

  td.vp-tag {
    font-weight: bold;
  }

  td.td-pond-vp-cell {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    background-color: #BED0FB;
    word-wrap: break-word;
  }

  td.td-pond {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    background-color: #DEDEDE;
    word-wrap: break-word;
  }

  td.td-pond-variable-name {
    border: 1px solid #D0D0D0;
    border-radius: 3px;
    background-color: #EEEEEE;
    word-wrap: break-word;
  }

  tr.tr-variable {
    background-color: #EEEEEE;
    word-wrap: break-word;
  }

</style>
