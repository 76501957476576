<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-row
      align="center"
      justify="center"
      style="height: 100%;"
    >
      <v-col
        cols="12"
        sm="8"
        :md="drawer ? '6' : '5'"
      >
        <v-card
          class="elevation-12"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Formulario de Registro</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <ValidationObserver
              ref="obs"
            >
              <v-form
                id="register-form"
                ref="registerForm"
                @submit.prevent="submitRegister"
              >
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="name"
                  rules="required|max:30|min:3"
                >
                  <v-text-field
                    v-model="name"
                    label="Nombre"
                    :error-messages="errors"
                    :success="valid"
                    name="name"
                    prepend-inner-icon="person"
                    type="text"
                    outlined
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="email"
                  rules="required|email|max:30"
                >
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :error-messages="errors"
                    :success="valid"
                    name="email"
                    prepend-inner-icon="mdi-at"
                    type="text"
                    outlined
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="country"
                  rules="required"
                >
                  <v-autocomplete
                    v-model="country"
                    :items="countries"
                    :filter="filterCountries"
                    label="País"
                    :error-messages="errors"
                    name="country"
                    item-value="text"
                    autocomplete="new-password"
                    hide-no-data
                    hide-selected
                    outlined
                  >
                    <template v-slot:item="{ item }">
                      <v-list>
                        <v-list-item class="d-flex align-center">
                          <img
                            class="mr-3"
                            :src="`https://flagsapi.com/${item.value.toUpperCase()}/flat/64.png`"
                            :alt="`Bandera de ${item.text}`"
                            style="width: 24px; height: 24px;"
                          />

                          {{ item.text }}
                        </v-list-item>
                      </v-list>
                    </template>
                    <template v-slot:prepend-inner>
                      <template v-if="!country">
                        <v-icon class="mr-1">mdi-map</v-icon>
                      </template>
                      <template v-else>
                        <img
                          :src="selectedCountryFlag"
                          :alt="`Bandera de ${country}`"
                          style="width: 24px; height: 24px; margin-top: -2px;"
                        />
                      </template>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="password"
                  rules="required|max:30|min:8"
                >
                  <v-text-field
                    id="password"
                    v-model="password"
                    label="Contraseña"
                    :error-messages="errors"
                    :success="valid"
                    name="password"
                    prepend-inner-icon="lock"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    outlined
                  />
                </ValidationProvider>
              </v-form>
            </ValidationObserver>
          </v-card-text>
          <v-card-actions>
            <v-btn
              to="/login"
              text
              color="primary"
            >
              Ya tengo una cuenta
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              :loading="loading"
              type="submit"
              form="register-form"
              @click="submitRegister"
            >
              Enviar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';
import { mapFields } from 'vuex-map-fields';
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';

export default {
  name: 'Register',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    name: '',
    email: '',
    password: '',
    country: '',
    countries: [],
    showPassword: false,
    loading: false,
    countrySearch: '',
  }),
  computed: {
    ...mapFields([
      'token',
      'user',
      'drawer',
    ]),
    selectedCountryFlag() {
      if (!this.country) return null;
      const code = this.countries.find(country => country.text === this.country).value;
      return `https://flagsapi.com/${code.toUpperCase()}/flat/64.png`;
    },
  },
  async mounted() {
    await this.fetchCountries();
  },
  methods: {
    fetchCountries() {
      return this.axios.get('/api/countries')
        .then((response) => {
          this.countries = Object.entries(response.data).map(([key, value]) => ({
            text: value,
            value: key,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterCountries(item, queryText) {
      if (!queryText?.trim()) return this.countries;

      const searchString = queryText.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      const formattedText = item.text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const formattedValue = item.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

      return formattedText.includes(searchString) || formattedValue.includes(searchString);
    },
    async submitRegister() {
      if (this.loading) {
        return null;
      }

      if (await this.$refs.obs.validate()) {
        this.loading = true;
        this.axios.post('/api/register', {
          name: this.name,
          email: this.email,
          password: this.password,
          country: this.country,
        })
          .then(({ data: { token, user } }) => {
            this.loading = false;
            this.token = token;
            this.user = user;
            this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            this.$router.push('/');
          })
          .catch((error) => {
            this.loading = false;

            if (error.response.status === 409) {
              this.$refs.obs.setErrors({
                email: ['Ya existe una cuenta con este email.'],
              });
            } else if (error.response.status === 422) {
              this.$refs.obs.setErrors(error.response.data.errors);
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Lo sentimos!',
                text: 'Ha ocurrido un error inesperado.',
              });
            }
          });
      }
    },
  },

};
</script>
