<template>
  <v-dialog
    :key="showDialog"
    v-model="showDialog"
    max-width="620px"
  >
    <v-card>
      <v-card-title>
        <span class="headline mb-4 center">Enlace y estatus de encuesta para
          <b v-if="currentSelectedUser">
          {{currentSelectedUser.name}}
          {{currentSelectedUser.lastname}}
          </b>
        </span>
      </v-card-title>
      <v-card-text>
          <p class = "section-title mb-3 mt-2" style="font-weight:normal;">
            Encuesta {{ currentStatusSelectedUser }}
            <v-icon
            :color="currentStatusSelectedUserIconColor">
              {{ currentStatusSelectedUserIcon }}
            </v-icon>
          </p>
          <p class = "mb-6 mt-1 section-title" style="font-weight:normal;">
            <b>Copie el enlace</b> para compartir la encuesta<br>
          </p>
            <v-text-field
              :value="inviteLink"
              color="blue"
              solo
              readonly
              append-icon = "mdi-link"
              @click:append="copyLink(inviteLink)"
            >
              <slot slot="append" name="end" />s
            </v-text-field>
          <div class = "invite-button">
            <v-btn color="info" @click="copyLink(inviteLink)">
            Copiar enlace <v-icon class="ml-2">mdi-clipboard</v-icon>
            </v-btn>
          </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="showDialog = false"
        >
          cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
import { mapFields } from 'vuex-map-fields';


export default {
  name: 'SurveyStatusModal',
  components: {
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    dialog: false,
    currentSelectedUser: null,
    surveysInfo: null,
    isQuantificationSurvey: false,
  }),
  methods: {
    setNecesaryInfo(user, surveysInfo, isQuantificationSurvey = false) {
      this.isQuantificationSurvey = isQuantificationSurvey;
      this.currentSelectedUser = user;
      this.surveysInfo = surveysInfo;
    },
    copyLink(link) {
      navigator.clipboard.writeText(link);
    },
  },
  computed: {
    ...mapFields({
      diagram: 'project.diagram',
    }),
    baurl() {
      return process.env.VUE_APP_BASE_URL;
    },
    currentStatusSelectedUserIconColor() {
      if (this.surveysInfo) {
        switch (this.surveysInfo.data.find(
          survey => survey.contact_id === this.currentSelectedUser.id,
        ).status) {
          case 'not-started':
            return 'light-gray';
          case 'in-progress':
            return 'light-blue';
          case 'finished':
            return 'green';
          default:
            return '';
        }
      } return '';
    },
    currentStatusSelectedUserIcon() {
      if (this.surveysInfo) {
        switch (this.surveysInfo.data.find(
          survey => survey.contact_id === this.currentSelectedUser.id,
        ).status) {
          case 'not-started':
            return 'mdi-clock';
          case 'in-progress':
            return 'mdi-clock';
          case 'finished':
            return 'mdi-check';
          default:
            return '';
        }
      } return '';
    },
    currentStatusSelectedUser() {
      if (this.surveysInfo) {
        switch (this.surveysInfo.data.find(
          survey => survey.contact_id === this.currentSelectedUser.id,
        ).status) {
          case 'not-started':
            return 'no iniciada';
          case 'in-progress':
            return 'en progreso';
          case 'finished':
            return 'finalizada';
          default:
            return '';
        }
      } return '';
    },
    inviteLink() {
      if (this.surveysInfo) {
        return `${this.baurl}/encuesta${this.isQuantificationSurvey ? '-cuantificar' : ''}/${this.surveysInfo.data.find(
          survey => survey.contact_id === this.currentSelectedUser.id,
        ).token}`;
      } return '';
    },
    showDialog: {
      get() {
        return this.show || this.dialog;
      },
      set(val) {
        if (val === false) {
          this.$emit('close');
        }
        this.dialog = val;
      },
    },
  },
};
</script>
