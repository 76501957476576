<template>
  <v-dialog
    :key="showDialog"
    v-model="showDialog"
    max-width="1000px"
    @keydown.esc="showDialog = false"
    @keydown.enter="submitForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ cardTitle }}</span>
      </v-card-title>
      <v-card-text>
        <ValidationObserver
          ref="obs"
        >
          <v-form ref="contactForm">
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="name"
              rules="required"
            >
              <v-text-field
                v-model="name"
                label="Nombre"
                :error-messages="errors"
                :success="valid"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="lastname"
              rules="required"
            >
              <v-text-field
                v-model="lastname"
                label="Apellido"
                :error-messages="errors"
                :success="valid"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors, valid }"
              name="email"
              rules="required|email"
            >
              <v-text-field
                v-model="email"
                label="Email"
                :error-messages="errors"
                :success="valid"
              />
            </ValidationProvider>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="showDialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="submitForm"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script type="text/javascript">
import Swal from 'sweetalert2';
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';

export default {
  name: 'ContactForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    item: {
      type: Object,
      default: null,
    },
    fromSurvey: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: false,
    name: '',
    lastname: '',
    email: '',
    loading: false,
  }),
  computed: {
    cardTitle() {
      if (this.item === null) {
        if (this.fromSurvey) {
          return 'Crear e importar contacto';
        }
        return 'Crear contacto';
      }
      return `Editar contacto #${this.item.id}`;
    },
    showDialog: {
      get() {
        return this.show || this.dialog;
      },
      set(val) {
        if (val === false) {
          this.resetForm();
          this.$emit('close');
        }
        this.dialog = val;
      },
    },
  },
  watch: {
    item() {
      if (this.item !== null) {
        this.name = this.item.name;
        this.lastname = this.item.lastname;
        this.email = this.item.email;
      }
    },
  },
  methods: {
    submitForm() {
      if (this.loading) {
        return null;
      }

      this.$refs.obs.validate()
        .then((valid) => {
          if (valid) {
            if (this.item) {
              this.update();
            } else if (this.fromSurvey) {
              this.createAndImport();
            } else {
              this.create();
            }
          }
        });
    },
    resetForm() {
      this.name = '';
      this.lastname = '';
      this.email = '';
      this.$refs.obs.reset();
    },
    createAndImport() {
      this.axios.post(`/api/surveys/${this.$route.params.id}/invite/new_contact`, {
        name: this.name,
        lastname: this.lastname,
        email: this.email,
      })
        .then((response) => {
          this.showDialog = false;
          Swal.fire({
            title: 'Excelente',
            text: `${response.data.message}`,
            icon: 'success',
          })
            .then(() => {
              this.$emit('updateList');
            });
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            Swal.fire({
              title: 'Error',
              text: `${error.response.data.message}`,
              icon: 'error',
            });
            return;
          }

          Swal.fire({
            title: 'Lo sentimos!',
            text: 'Ha ocurrido algun error.',
            icon: 'error',
          });
        });
    },
    create() {
      this.axios.post('/api/contacts', {
        name: this.name,
        lastname: this.lastname,
        email: this.email,
      })
        .then((response) => {
          Swal.fire({
            title: 'Excelente',
            text: `${response.data.message}`,
            icon: 'success',
          })
            .then(() => {
              this.showDialog = false;
              this.$emit('updateList');
            });
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            Swal.fire({
              title: 'Error',
              text: `${error.response.data.message}`,
              icon: 'error',
            });
            return;
          }

          Swal.fire({
            title: 'Lo sentimos!',
            text: 'Ha ocurrido algun error.',
            icon: 'error',
          });
        });
    },
    update() {
      this.axios.put(`/api/contacts/${this.item.id}`, {
        name: this.name,
        lastname: this.lastname,
        email: this.email,
      })
        .then((response) => {
          Swal.fire({
            title: 'Excelente',
            text: response.data.message,
            icon: 'success',
          })
            .then(() => {
              this.showDialog = false;
              this.$emit('updateList');
            });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            Swal.fire({
              title: 'Lo sentimos!',
              text: error.response.data.message,
              icon: 'error',
            });
            return;
          }

          if (error?.response?.status === 409) {
            Swal.fire({
              title: 'Error',
              text: `${error.response.data.message}`,
              icon: 'error',
            });
            return;
          }

          Swal.fire({
            title: 'Lo sentimos!',
            text: 'Ha ocurrido algun error.',
            icon: 'error',
          });
        });
    },
  },
};
</script>
