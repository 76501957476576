<template>
  <v-container
    fluid
    class="fill-height"
  >
    <v-main>
      <v-row
        align="center"
        justify="center"
        style="height: 100%;"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
        >
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>Escoge una nueva contraseña</v-toolbar-title>
            </v-toolbar>
            <ValidationObserver
              ref="obs"
            >
              <v-form
                id="reset-password-form"
                ref="resetPasswordForm"
                @submit.prevent="submit"
              >
                <v-card-text>
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="email"
                    rules="required|email|max:30"
                  >
                    <v-text-field
                      v-model="email"
                      label="Email"
                      :error-messages="errors"
                      :success="valid"
                      name="login"
                      prepend-inner-icon="mdi-at"
                      type="text"
                      outlined
                    />
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="password"
                    rules="required|max:30|min:8"
                  >
                    <v-text-field
                      id="password"
                      v-model="password"
                      label="Escoge una nueva contraseña"
                      :error-messages="errors"
                      :success="valid"
                      name="password"
                      prepend-inner-icon="lock"
                      :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      outlined
                    />
                  </ValidationProvider>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="red"
                    class="white--text"
                    to="/login"
                  >Volver</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    type="submit"
                  >Enviar enlace</v-btn>
                </v-card-actions>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import Swal from 'sweetalert2';

export default {
  name: 'ForgotPassword',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    email: '',
    loading: false,
    password: '',
    showPassword: false,
    token: '',
  }),
  mounted() {
    const url = new URL(window.location.href);
    this.token = url.searchParams.get('token');
    this.email = url.searchParams.get('email');
  },
  methods: {
    submit() {
      if (!this.$refs.obs.validate()) {
        console.log('validating');
        return;
      }

      this.loading = true;
      this.axios.post('/api/reset-password', {
        email: this.email,
        password: this.password,
        token: this.token,
      })
        .then(() => {
          Swal.fire({
            title: '¡Listo!',
            text: 'Tu contraseña ha sido cambiada con éxito',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          }).then(() => {
            this.$router.push('/login');
          });
        })
        .catch((error) => {
          Swal.fire({
            title: '¡Error!',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
